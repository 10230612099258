import React,{useEffect} from 'react'
import { Container} from 'react-bootstrap'
import ProgramsList from '../components/ProgramsList';

import ProgramContextprovider from '../context/ProgramContext';
import ProgramEditorModal from '../components/ProgramEditorModal';
import CourseContextprovider from '../context/CourseContext';
import SettingContextprovider from '../context/SettingContext';
import {FaTable} from 'react-icons/fa'
import FrameworkContextprovider from '../context/FrameworkContext';
import { useParams } from 'react-router-dom';
import SideMenuToggle from '../components/SideMenuToggle';
export default function ProgramsManagementPage() {
  const {programID}=useParams()
  return (
   <>

   <ProgramContextprovider>
   <SettingContextprovider>
    <FrameworkContextprovider>
      <CourseContextprovider>
        <ProgramEditorModal/>
        <p className="appPageIcon"><FaTable/></p>
        <h2 className="appPageTitle"> | Programs Management</h2>
       
        {programID?<ProgramsList initialProgramId={programID}/>:<ProgramsList/>}
       
      </CourseContextprovider>
      </FrameworkContextprovider>
      </SettingContextprovider>
  </ProgramContextprovider>




   </>
  )
}
