import React,{useState} from 'react'
import {Row,Col,Form,Badge} from 'react-bootstrap'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import './ContactBadgeEditor.css';
export default function ContactBadgeEditor(props) {
    const [stakeholder,setStakeholder]=useState(props.stakeholder)
    const [editMode,setEditMode]=useState(false);
    const handleInputChange=(event)=>{
        let value=event.target.value;
        console.log(value)
        let updatedStakeholder={...stakeholder};
        
        
        setStakeholder({...updatedStakeholder,[event.target.name]:value})
    
    }
    const showEditMode=()=>{
        setEditMode(true);
    }
    const handleSaveAndClose=(event)=>{
        let stakeholderId=event.target.getAttribute("stakeholderid");
        console.log(stakeholderId)
        if (stakeholderId){
          props.saveAStakeholder(stakeholderId,stakeholder);
          setEditMode(false);}
    }
    const handleDeleteContactBadge=(event)=>{
        let stakeholderId=event.target.getAttribute("stakeholderid");
        console.log(stakeholderId)
        props.deleteStakeholder(stakeholderId);
    }
    return (
    <>
    {editMode?
    <div className="badgeEditorContainer">
    <Row>
    <Col lg="4" md="4" xs="4" sm="4" className="appEditorLabel ">
      <Form.Label >Full Name</Form.Label>
    </Col>
    <Col>
      <Form.Control name="fullName"
      type="text" 
      defaultValue={stakeholder.fullName}
      onChange={handleInputChange} 
      ></Form.Control>
    </Col>
  </Row>
  <Row>
  <Col lg="4" md="4" xs="4" sm="4" className="appEditorLabel ">
    <Form.Label >Organization</Form.Label>
  </Col>
  <Col>
    <Form.Control name="organization"
    type="text" 
    defaultValue={stakeholder.organization}
    onChange={handleInputChange} 
    ></Form.Control>
  </Col>
</Row>
<Row>
  <Col lg="4" md="4" xs="4" sm="4" className="appEditorLabel  ">
    <Form.Label >Email</Form.Label>
  </Col>
  <Col>
    <Form.Control name="email"
    type="text" 
    defaultValue={stakeholder.email}
    onChange={handleInputChange} 
    ></Form.Control>
  </Col>
</Row>
<Row>
  <Col lg="4" md="4" xs="4" sm="4" className="appEditorLabel">
    <Form.Label >Phone Number</Form.Label>
  </Col>
  <Col>
    <Form.Control name="phoneNumber"
    type="text" 
    defaultValue={stakeholder.phoneNumber}
    onChange={handleInputChange} 
    ></Form.Control>
  </Col>
</Row>
<Row>
  <Col lg="4" md="4" xs="4" sm="4" className="appEditorLabel">
    <Form.Label >Role</Form.Label>
  </Col>
  <Col>
    <Form.Control name="role"
    type="text" 
    defaultValue={stakeholder.role}
    onChange={handleInputChange} 
    ></Form.Control>
  </Col>
</Row>
<Row>
  <Col lg="4" md="4" xs="4" sm="4" className="appEditorLabel">
    <Form.Label >Mapping</Form.Label>
  </Col>
  <Col>
    <Form.Select 
    name="mapping"
    value={stakeholder.mapping} 
    
    onChange={handleInputChange} 
    >
      <option value="Keep Satisfied">Keep Satisfied (High power + Low influence)</option>
      <option value="Manage Closely">Manage Closely (High power + High influence)</option>
      <option value="Monitor">Monitor (Low power + Low influence)</option>
      <option value="Keep Informed">Keep Informed (Low power + High influence)</option>
    </Form.Select>
  </Col>
</Row>
<Row>
    <Col ><button stakeholderid={stakeholder._id}  className="saveAndCloseButton" onClick={handleSaveAndClose}>Save and Close</button></Col>
</Row>
    
</div>

    :
    
    <Badge className="stakeholderEditorBadge">{stakeholder.fullName} | <span className="buttonIcon" onClick={showEditMode}><FaPencilAlt /></span> | <span className="buttonIcon" stakeholderid={stakeholder._id} onClick={handleDeleteContactBadge}><FaTrashAlt /></span> </Badge>}
   </>
     
    

    

  )
}
