import React,{useState} from 'react'
import {Row, Form, Col} from 'react-bootstrap'
import CompetencyEditor from './CompetencyEditor'
import {FaRegPlusSquare,FaRegMinusSquare,FaCube,FaTrashAlt} from 'react-icons/fa'
import { useFramework } from '../context/FrameworkContext'
import './FunctionEditor.css';
export default function FunctionEditor(props) {
  const [isExpanded,setIsExpanded]=useState(false)
  const {addEmptyCompetency,changeSingleValue,removeFunction,setMessage}=useFramework();
  const handleToggleExpand=()=>{
    setIsExpanded(!isExpanded);
  }
  const handleInputChange=(event)=>{
    let fieldValue=event.target.value;
    let fieldName=event.target.name;
    
    changeSingleValue(fieldName,fieldValue,props.roleID,props.id,'*');
  }
 
  const handleDeleteFunction=()=>{
    setMessage(
    { header:"Deleting a Function",
      text:"Are yuo sure you want to edit this function and all its related competencies",
      variant:'warning',
      isVisibile:true,
      buttons:[{text:"Delete Function",action:removeTheFunction},{text:"Cancel",action:hideTheMessage}]

    }
    )
  }

  const hideTheMessage=()=>{
    setMessage({header:"",text:"",variant:"",buttons:[],isVisible:false})
  }
  const removeTheFunction=()=>{
    removeFunction(props.roleID,props.id)
    hideTheMessage();
    
  }

  const handleAddCompetency=()=>{
    addEmptyCompetency(props.roleID,props.id);
  }
  return (
    <>
      <div >
      <Row>
        
      <Col>
          <Row>
          <Col lg="2" md="3" xs="4" sm="4" className="appEditorLabel functionEditorLabel ">
            <Form.Label><div className="appHorizontalLine"></div>Name</Form.Label>
          </Col>
          <Col>
            <Form.Control name="name"
                          type="text" 
                          defaultValue={props.name}
                          onChange={handleInputChange}
                          ></Form.Control>
          </Col>
          </Row>
          <Row>
          <Col lg="2" md="3" xs="4" sm="4" className="appEditorLabel functionEditorLabel ">
            <Form.Label>Description&nbsp;&nbsp;</Form.Label>
          </Col>
          <Col>
            <Form.Control name="description"
                          type="text"
                          defaultValue={props.description}
                          onChange={handleInputChange}
                          ></Form.Control>
          </Col>
          </Row>
        </Col>
        <Col lg="1" md="1" sm="1" xs="1">
          <div className="appIcon">
          <FaTrashAlt onClick={handleDeleteFunction}/>
          </div>
        </Col>
        </Row>
     </div>
     <div>
     <div className="appTreeLines">
     {<div className="expandCollapseButton" onClick={handleToggleExpand}>{isExpanded?<FaRegMinusSquare/>:<FaRegPlusSquare/>} <FaCube/><b> Competencies ({props.competencies.length})</b></div>}
      <div className="appIdented" style={{display:isExpanded?'block':'none'}}>
      
      {props.competencies.map((competency)=>{
        return(
        <>
        
        <Row>
        <CompetencyEditor 
          key={competency._id}
          functionID={props.id}
          roleID={props.roleID}
          id={competency._id}
          name={competency.name}
          description={competency.description}
          type={competency.type}
          level={competency.level}
        />
        </Row>
        </>)
      })}
       <button onClick={handleAddCompetency} className="appEditorButtons" style={{left:'-30px',top:'20px'}}>+ <FaCube/></button>
      
       
      </div>
     
      <p> </p>
    </div>
    </div>
    <p></p>  
    <div style={{height:'20px'}}></div>
   

    </>
  )
}
