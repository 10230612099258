import React from 'react'

import SignUp from '../components/SignUp'


export default function SignUpPage() {
  
  return (
    <>
    
      <div>
          <SignUp></SignUp>
      </div>
   
      </>

  )
}
