import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import {FaInfoCircle, FaInfo} from 'react-icons/fa'
import homeImage1 from '../assets/homeImage1.png'
import './AboutUsPage.css'


export default function ABoutUsPage() {
  return (
    <Container>
    <p className="appPageIcon"><FaInfo/></p>
      <h2 className="appPageTitle"> | About Us</h2>
      <p></p>
      <Row >
        <Col lg="7" md="7" sm="12" xs="12" className="textPart">
        
        <p>Edu Framework is an educational platform created as a project by <strong>"Logi Owl Content Creators Canada Inc."</strong> to helps informed training design and development, it was built using the best practices in instructional design to embed the adult learning concepts and theory as part of the process.
          <p>The focus when building workin on the project was representation and reporting connecting all steps from competency frame work building and task analysis to certification covering programs, courses,leanrning objectives and activities </p> 
        </p>
        </Col>
        <Col lg="5" md="5" sm="12" xs="12">
          <img src={homeImage1} width="80%"/>
        </Col>
       
      </Row>
  
   
    </Container>
    
  )
}
