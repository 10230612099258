import React,{useState,useEffect} from 'react'
import {Row,Col,Form,Badge} from 'react-bootstrap'
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { v4 as uuid } from 'uuid';
import {useProgram} from '../context/ProgramContext'
import { useCourse } from '../context/CourseContext';
import ContactBadgeEditor from './ContactBadgeEditor';
import {useSettings} from '../context/SettingContext'
import { useFramework } from '../context/FrameworkContext';
export default function ProgramEditor(props) {
  const {setUserPrograms,userPrograms,selectedProgramId,selectedProgram,setSelectedProgram}=useProgram();
  const {userCourses,getUserCourses}=useCourse();
  const {userFrameworks,getFrameworks}=useFramework();
  const {getSettingByName,settingData}=useSettings();
  useEffect(()=>{
    getUserCourses();
    getFrameworks();
    getSettingByName("deliveryMethods");
    console.log(settingData)
    
  },[])
  
  const [programCourses,setProgramCourses]=useState(selectedProgram.courses)
  const [programFrameworks,setProgramFrameworks]=useState(selectedProgram.relatedFrameworks)
  const handleInputChange=(event)=>{
    let value=event.target.value;
   
    let updatedUserProgram={...selectedProgram};
    let schema=updatedUserProgram;
    let path=event.target.name;
    let nestedItemsList=path.split('.');
    let nestedLevels=nestedItemsList.length;
    for (let i=0;i<nestedLevels-1;i++){
      let element=nestedItemsList[i];
      if (!schema[element]) schema[element]={}
      schema=schema[element];
    }
    schema[nestedItemsList[nestedLevels-1]]=value
    setSelectedProgram({...updatedUserProgram})
    

}

  const selectedOptionAsArray=(theSelectedOptions)=>{
    let selectedOptionsArray=[];
    for(let i=0;i<theSelectedOptions.length;i++){
      selectedOptionsArray.push(theSelectedOptions.item(i).value)
    }
    console.log(selectedOptionsArray)
    return selectedOptionsArray;
  }
  const handleSelectedFrameworksChange=async(event)=>{
   
    let  updatedProgramFrameworks=await userFrameworks.filter((framework)=>selectedOptionAsArray(event.target.selectedOptions).includes(framework._id))
       setProgramFrameworks(updatedProgramFrameworks);
   
    let updatedSelectedProgram=selectedProgram;
    updatedSelectedProgram.relatedFrameworks=updatedProgramFrameworks
    setSelectedProgram(updatedSelectedProgram)
    console.log("I am here",updatedSelectedProgram)
  }
  const handleSelectedCoursesChange=async(event)=>{
      let  updatedProgramCourses=await userCourses.filter((course)=>selectedOptionAsArray(event.target.selectedOptions).includes(course._id))
       setProgramCourses(updatedProgramCourses);
   
    let updatedSelectedProgram=selectedProgram;
    updatedSelectedProgram.courses=updatedProgramCourses
    setSelectedProgram(updatedSelectedProgram)
   
  }
  const saveAStakeholder=(stakeholderId,newStakeholder)=>{
    let updatedUserProgram={...selectedProgram};
    let indexToUpdate= updatedUserProgram.stakeholders.findIndex(stakeholder=>stakeholder._id===stakeholderId)
    if (indexToUpdate>-1) {
      updatedUserProgram.stakeholders[indexToUpdate]=newStakeholder;
      setSelectedProgram(updatedUserProgram)
      
    }


  }
  
  const addAStakeholder=()=>{
    let uniqueId=uuid();
    let emptyStakeholder={_id:uniqueId,fullName:"Full Name Place holder",organization:"",email:"",phoneNumber:"",mapping:"",role:""};
    let updatedStakeholders=selectedProgram.stakeholders;
     updatedStakeholders.push(emptyStakeholder);

    setSelectedProgram({...selectedProgram,stakeholders:updatedStakeholders});

  }

  const deleteStakeholder=(stakeholderId)=>{
    let updatedStakeholders=selectedProgram.stakeholders;
    let toDeleteStakeholderIndex=updatedStakeholders.findIndex((stakeholder)=>stakeholder._id===stakeholderId);
    if (toDeleteStakeholderIndex>-1) {updatedStakeholders.splice(toDeleteStakeholderIndex,1)};
   
    setSelectedProgram({...selectedProgram,stakeholders:updatedStakeholders});
  }

  console.log(programCourses)
  return (
      <>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Name</Form.Label>
      </Col>
        <Col>
            <Form.Control   name="name"
            type="text" 
            defaultValue={selectedProgram.name}
            onChange={handleInputChange} 
            ></Form.Control> 
        </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Description</Form.Label>
      </Col>
      <Col>
        <Form.Control   name="description"
        type="text" 
        defaultValue={selectedProgram.description}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Level</Form.Label>
      </Col>
      <Col>
        <Form.Control name="level"
        type="text" 
        defaultValue={selectedProgram.level}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    
    <hr/>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Audience</Form.Label>
      </Col>
      <Col>
        <Form.Control name="audience.who"
        type="text" 
        defaultValue={selectedProgram.audience.who}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Background Knowledge</Form.Label>
      </Col>
      <Col>
        <Form.Control name="audience.backgroundKnowledge"
        type="text" 
        defaultValue={selectedProgram.audience.backgroundKnowledge}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Technology Comfort Level</Form.Label>
      </Col>
      <Col>
        <input name="audience.technologyComfortLevel"
        type="range"
        min="1" max="5"
        defaultValue={selectedProgram.audience.technologyComfortLevel}
        onChange={handleInputChange} 
        />
        <Badge className="bigBadge" bg="secondary">{selectedProgram.audience.technologyComfortLevel}</Badge>
      </Col>
      
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Math Comfort Level</Form.Label>
      </Col>
      <Col>
        <input name="audience.mathComfortLevel"
        type="range"
        min="1" max="5"
        defaultValue={selectedProgram.audience.mathComfortLevel}
        onChange={handleInputChange} 
        />
          <Badge className="bigBadge" bg="secondary">{selectedProgram.audience.mathComfortLevel}</Badge>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4"className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >From Age</Form.Label>
      </Col>
      <Col>
        <input name="audience.ageFrom"
        type="range"
        min="16" max="100" 
        defaultValue={selectedProgram.audience.ageFrom}
        onChange={handleInputChange} 
        />
        <Badge className="bigBadge" bg="secondary">{selectedProgram.audience.ageFrom}</Badge>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >To Age</Form.Label>
      </Col>
      <Col>
        <input name="audience.ageTo"
        min={selectedProgram.audience.ageFrom}
        max="100"
        min="16"
        type="range" 
        defaultValue={selectedProgram.audience.ageTo}
        onChange={handleInputChange} 
        />
        <Badge className="bigBadge" bg="secondary">{selectedProgram.audience.ageTo}</Badge>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Prefered Delivery Method</Form.Label>
      </Col>
      <Col>
      <Form.Select
      name="audience.preferedDeliveryMethod"
      defaultValue={selectedProgram.audience.preferedDeliveryMethod}
      onChange={handleInputChange}
      >
        {settingData?.deliveryMethods?.map((item,index)=>{
          return(
            
            <option key={index} value={item}>{item}</option>
            )
          
       })}
      </Form.Select>
        
      </Col>
    </Row>
    <hr/>
    <Row>
      <Col lg="11" md="11" xs="11" sm="11" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >StakeHolders</Form.Label>
      </Col>
    </Row>
    <Row>  
      <Col>
       {selectedProgram.stakeholders?.map((stakeholder)=>{
        return(
        <>
        <ContactBadgeEditor 
          stakeholder={stakeholder} 
          saveAStakeholder={saveAStakeholder}
          deleteStakeholder={deleteStakeholder}></ContactBadgeEditor>
        <span> </span>
        </>
        )})}
        <Badge onClick={addAStakeholder}>+</Badge>
      </Col>
   
    </Row>
    <Row>
          <Col>
          <Form.Group  controlId="programFrameworksSelect">
            <Form.Label>This Program was created as a partial or full reuirement to cover the following frameworks competencies:</Form.Label>
            <Form.Control as="select" multiple value={programFrameworks?.map(framework=>framework._id)} onChange={handleSelectedFrameworksChange}>
            {userFrameworks?.map((framework,index)=>{
            return (
             
            <option key={index} value={framework._id} >
              {framework.frameworkName} 
            </option>
             
            )})}
              
            </Form.Control>
          </Form.Group>
          
          
          </Col>
    </Row>
    <Row>
          <Col>
          <Form.Group  controlId="programCoursesSelect">
            <Form.Label>Program's Courses</Form.Label>
            <Form.Control as="select" multiple value={programCourses.map(course=>course._id)} onChange={handleSelectedCoursesChange}>
            {userCourses?.map((course,index)=>{
            return (
             
            <option key={index} value={course._id} >
              {course.name} 
            </option>
             
            )})}
              
            </Form.Control>
          </Form.Group>
          
          
          </Col>
    </Row>
    </>
  )
}
