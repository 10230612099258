import React from 'react'
import {Nav} from 'react-bootstrap' 
import './SocialBar.css'
import {FaFacebookF,FaYoutube,FaLinkedin,FaPinterest,FaTwitter,FaInstagram} from 'react-icons/fa'
export default function SocialBar(props) {
  return (
    <Nav
        className="socialBar"
      activeKey="/home"
      onSelect={(selectedKey) => window.open(selectedKey,'_blank')}
    >
      {props.facebook?<Nav.Item>
        <Nav.Link eventKey={props.facebook}><FaFacebookF/></Nav.Link>
      </Nav.Item>:null}
      {props.instagram?<Nav.Item>
        <Nav.Link eventKey={props.instagram}><FaInstagram/></Nav.Link>
      </Nav.Item>:null}
      {props.linkedIn?<Nav.Item>
        <Nav.Link eventKey={props.linkedIn}><FaLinkedin/></Nav.Link>
      </Nav.Item>:null}
      {props.pInterest?<Nav.Item>
        <Nav.Link eventKey={props.pInterest}><FaPinterest/></Nav.Link>
      </Nav.Item>:null}
      {props.twitter?<Nav.Item>
        <Nav.Link eventKey={props.twitter}><FaTwitter/></Nav.Link>
      </Nav.Item>:null}
      
      {props.youTube?<Nav.Item>
        <Nav.Link eventKey={props.youTube}><FaYoutube/></Nav.Link>
      </Nav.Item>:null}
      
    </Nav>

  )
}
