import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt, FaInfoCircle } from "react-icons/fa";
import { useFramework } from "../context/FrameworkContext";
import "./FrameworksList.css";
import Message from "./Message";

export default function FrameworksList(props) {
  const [listMessage, setListMessage] = useState({
    header: "",
    text: "",
    variant: "",
    buttons: [],
    isVisible: false,
  });

  const {
    userFrameworks,
    selectedFrameworkID,
    setSelectedFrameworkID,
    setFramework,
    addEmptyFramework,
    getFrameworks,
    setEditModalIsVisible,
    removeFramework,
  } = useFramework();

  // Synchronize frameworks and handle initial selection
  useEffect(() => {
    const initializeFrameworks = async () => {
      await getFrameworks();
      if (props.initialFrameworkId) {
        selectFrameworkById(props.initialFrameworkId);
      } else if (userFrameworks.length > 0) {
        selectFrameworkById(userFrameworks[0]._id);
      }
    };
    initializeFrameworks();
  }, [props.selectedFrameworkID]);

  // Select framework by ID and synchronize state
  const selectFrameworkById = (frameworkID) => {
    setSelectedFrameworkID(frameworkID);
    const selectedFramework = userFrameworks.find((fw) => fw._id === frameworkID);
    if (selectedFramework) {
      setFramework(selectedFramework);
    }
  };

  // Handle adding a new framework
  const handleAddFrameworkButton = async () => {
    const newFrameworkID = await addEmptyFramework();
    if (newFrameworkID) {
      selectFrameworkById(newFrameworkID);
    }
  };

  // Handle selecting a framework from the list
  const handleSelectFramework = (event) => {
    const selectedItemID = event.target.getAttribute("itemid");
    if (selectedItemID) {
      selectFrameworkById(selectedItemID);
    }
  };

  // Handle editing a framework
  const handleEditFramework = (event) => {
    const selectedItemID = event.target.getAttribute("itemid");
    if (selectedItemID) {
      selectFrameworkById(selectedItemID);
      setEditModalIsVisible(true);
    }
  };

  // Handle deleting a framework
  const handleDeleteFramework =  (event) => {
    const selectedItemID =  event.target.getAttribute("itemid");
    if (selectedItemID) {
      selectFrameworkById(selectedItemID);
      setListMessage({
        header: "Delete a Framework",
        text: "Are you sure that you want to delete this framework and all related roles? This action is permanent.",
        variant: "warning",
        buttons: [
          { text: "Yes, Delete", action:()=> handleConfirmDelete(selectedItemID) },
          { text: "Cancel", action: handleCancelDelete },
        ],
        isVisible: true,
      });
    }
  };

  // Confirm deletion
  const handleConfirmDelete =  async(selectedItemID) => {
    
     await removeFramework(selectedItemID);
    setListMessage({
      header: "",
      text: "",
      variant: "",
      buttons: [],
      isVisible: false,
    });
    // Select another framework if one exists
    if (userFrameworks.length > 0) {
      selectFrameworkById(userFrameworks[0]._id);
    }
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setListMessage({
      header: "",
      text: "",
      variant: "",
      buttons: [],
      isVisible: false,
    });
  };

  return (
    <>
      <Row className="frameworksListHeaders">
        <Col>
          Frameworks ({userFrameworks.length})
          <button className="addFrameworkButton" onClick={handleAddFrameworkButton}>
            +
          </button>
        </Col>
        <Message
          header={listMessage.header}
          text={listMessage.text}
          buttons={listMessage.buttons}
          show={listMessage.isVisible}
          variant={listMessage.variant}
          position="relative"
        />
      </Row>
      <Row className="frameworksListContainer">
        <Col lg="12" md="12" sx="12" sm="12">
          {userFrameworks.map((oneFramework, index) => (
            <Row
              key={index}
              className={
                "frameworksListItem " +
                (oneFramework._id === selectedFrameworkID ? "frameworkSelected" : "")
              }
            >
              <Col sm="1" sx="3" md="2" lg="1">
                <input
                  type="radio"
                  name="selectedFrameworkID"
                  value={oneFramework._id}
                  className="frameworkSelector"
                  itemid={oneFramework._id}
                  checked={selectedFrameworkID === oneFramework._id}
                  onClick={handleSelectFramework}
                  onChange={handleSelectFramework}
                />
              </Col>
              <Col sm="8" sx="4" md="8" lg="9">
                <label>
                  <b>&nbsp;{oneFramework.frameworkName}</b>
                </label>
              </Col>
              <Col sm="3" sx="5" md="2" lg="2">
                <button
                  key={"edit" + index}
                  itemid={oneFramework._id}
                  className="appButtonIcons"
                  onClick={handleEditFramework}
                >
                  <FaPencilAlt />
                </button>
                <button
                  key={"delete" + index}
                  itemid={oneFramework._id}
                  className="appButtonIcons"
                  onClick={handleDeleteFramework}
                >
                  <FaTrashAlt />
                </button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      {userFrameworks.length === 0 && (
        <Row className="emptyListInformation">
          <p>
            <br />
            <FaInfoCircle /> This list is empty. Click the{" "}
            <div className="blackButton">+</div> button to add a new framework.
          </p>
        </Row>
      )}
    </>
  );
}
