import React, { useEffect, useState } from 'react';
import { useSettings } from '../context/SettingContext';
import './BloomDomainViewer.css';
import { Row} from 'react-bootstrap'

export default function BloomDomainViewer(props) {
    const { settingData,getSettingByName } = useSettings();
    const [domainInfo, setDomainInfo] = useState({});

    const getDomainInfo = async () => {
        if (settingData?.domains) {
            let settingInfo = [...settingData.domains];
            let theDomainInfo = settingInfo.filter(element => element.name === props.domainName);
            setDomainInfo(theDomainInfo[0]);
        }
    };

    useEffect(() => {
        if (!settingData.domains) {
            getSettingByName("domains");
        }
        getDomainInfo();
    }, [props, settingData]);

    return (
        <>
        <Row className="domain" > 
        
            {domainInfo?.levels?.map((level, index) => (
              <div className="domainWithMastery">
               
                <div
                    key={index}
                    className="domainLevel"
                    style={{
                        width: `${150 - index * 10}px`,
                        backgroundColor: level.colorCode,
                        left: `${-10 + index * 3}px`,
                        top: `${220 - index * 65}px`,
                        borderStyle: level.name === props.levelName ? 'solid' : 'none'
                    }}
                >
                    {level.name}
                </div>
                
                
                </div>
            ))}
          
         

        </Row>  
          <Row lg="1" md="1" xs="1" sd="1">
         <span class="masteryLevel" >{props.domainName} domain - &nbsp;{props.masteryLevel} mastery level</span>
          </Row>  
          </>
        
    );
}

// import React, {useEffect,useState} from 'react'
// import {FaArrowRight} from 'react-icons/fa'
// import  { useSettings } from '../context/SettingContext';
// import "./BloomDomainViewer.css"
// export default function BloomDomainViewer(props) {
//   const {settingData}=useSettings();
//   const [domainInfo,setDomainInfo]=useState({})

//   const getDomainInfo=async()=>{
//     if(settingData?.domains){
//       let settingInfo= [...settingData.domains];
//       let theDomainInfo= settingInfo?.filter(element=>{return element.name===props.domain});
//        setDomainInfo(theDomainInfo[0])
//     }
    
//   } 
 
//   useEffect(()=>{
//   if(!settingData.domains){

//   }
//    getDomainInfo();
//     console.log(domainInfo)
   
    
//   }
   
//   ,[props])
  
//   return (
//     <>
    

//     <div className="domain">
//     {domainInfo?.levels?.map((level,index)=>{
//       return(
        
        
//         <div className="domainLevel" style={{width:180-index*10,backgroundColor:level.colorCode,left:parseInt(5+index*5)+"px",top:(160-index*60)+"px",borderStyle:level.name===props.level?"solid":null}}> {level.name}</div>
        
    
//       )
//     })}
//     </div>
    
//    </>
//   )
// }

