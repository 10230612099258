import React from 'react'
import PlanPurchase from '../components/PlanPurchase'
import { Container } from 'react-bootstrap'
import { FaCartPlus} from 'react-icons/fa'
import PlanContextProvider from '../context/PlanContext'

export default function PurchasePlanPage() {
  return (
    <PlanContextProvider>
    <Container>     <p className="appPageIcon"><FaCartPlus />
</p>
        <h2 className="appPageTitle"> | Purchasing a Plan</h2>
    <div><PlanPurchase/></div>
    </Container>
    </PlanContextProvider>

  )
}
