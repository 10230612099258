import React, { useState } from 'react'
import { Col,Row} from 'react-bootstrap'
import CompetencyHoverTooltip from '../components/CompetencyHoverTooltip';
import Framework from '../components/Framework';
import FrameworkContextprovider from '../context/FrameworkContext';
import FrameworkEditorModal from '../components/FrameworkEditorModal';
import FrameworksList from '../components/FrameworksList';
import { useParams } from 'react-router-dom';
import frameworkIcon from '../assets/frameworkIcon.svg'
import SideMenuToggle from '../components/SideMenuToggle';

export default function FrameworksManagementPage() {


const {frameworkID}=useParams()

  return (
   <>
  
   <FrameworkContextprovider>
        <p className="appPageIcon"><img src={frameworkIcon} width="20px"/></p>
        <h2 className="appPageTitle"> | Frameworks Management</h2>
       <Row>
        <SideMenuToggle>
        <Col lg="3" md="3" sm="5" sx="12">
          {frameworkID?<FrameworksList initialFrameworkId={frameworkID}/>:<FrameworksList/>}
        </Col>
        </SideMenuToggle>
        <Col>
        <FrameworkEditorModal/>
        <Framework/>
        <CompetencyHoverTooltip />
        </Col>
       </Row>
        
    </FrameworkContextprovider>
 
  


   </>
  )
}
