
import './App.css';
import{React} from 'react'
import AppRoutes from './AppRoutes'
import CookieConsent from './components/CookiesConsent';



function App() {

  
  return (
    <>
 
    <AppRoutes/>
   
    </>
  );
}

export default App;
