import React from 'react'
import SignIn from '../components/SignIn'

export default function SignInPage() {
  let signedIn=true
  return (
    <>
    <div>
        <SignIn></SignIn>
    </div>
    </>
  )
}
