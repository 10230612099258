import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import PrivacyPolicyImage from '../assets/PrivacyPolicyImage.png'

import { FaEye, FaInfoCircle } from 'react-icons/fa'
export default function PrivacyPolicyPage() {
  return (
    <>
    <Container>
    <p className="appPageIcon"><FaEye /></p>
    <h2 className="appPageTitle"> | Privacy Policy</h2>
    <div className="homeContainer">
   
    <Row>
      <Col lg="6" md="6" sm="12" xs="12" className="textPart">
        <h2> Introduction</h2>
        <p>Welcome to Edu Framework application. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at [Your Contact Information].</p>
     
        <h2> Information We Collect</h2>
        <p>We collect personal information that you voluntarily provide to us when registering at the website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the website or otherwise contacting us.</p>
        <p>The personal information that we collect depends on the context of your interactions with us and the website, the choices you make, and the products and features you use. The personal information we collect can include the following:</p>
        <ul>
          <li>Name and Contact Data: We collect your first and last name, email address, postal address, phone number, and other similar contact data.</li>
          <li>Credentials: We collect passwords, password hints, and similar security information used for authentication and account access.</li>
          <li>Payment Data: We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument.</li>
        </ul>
      
        <h2>How We Use Information</h2>
        <p>We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
        <ul>
          <li>To facilitate account creation and logon process.</li>
          <li>To send administrative information to you.</li>
          <li>To fulfill and manage your orders.</li>
          <li>To post testimonials.</li>
          <li>Request feedback.</li>
          <li>To protect our services.</li>
          <li>To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</li>
        </ul>
      </Col>
      <Col lg="6" md="6" sm="12" xs="12">
        <img src={PrivacyPolicyImage} width="80%" />
      </Col>
    </Row>
    
    <Row>
      <Col lg="12" md="12" sm="12" xs="12" className="textPart">
        <h2>Sharing Information</h2>
        <p>We may process or share data based on the following legal basis:</p>
        <ul>
          <li>Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
          <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
          <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
          <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
        </ul>
      </Col>
  
    </Row>
    
    <Row>
      <Col lg="12" md="12" sm="12" xs="12" className="textPart">
        <h2>Security of Your Information</h2>
        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
      </Col>
    
    </Row>
    
    <Row>
      <Col lg="12" md="12" sm="12" xs="12" className="textPart">
        <h2>Policy Changes</h2>
        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>
        <p>We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      </Col>
     
    </Row>
    
    <Row>
      <Col lg="12" md="12" sm="12" xs="12" className="textPart">
        <h2> Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul>
          <li>By email: <a href="mailto:info@eduframework.ca">info@eduframework.ca</a></li>
          <li>By visiting this page on our website: <a href="https://eduframework.ca">https://eduframework.ca</a></li>
          <li>By phone number: +1 (587)287-3005</li>

        </ul>
      </Col>
      
    </Row>
  </div>
  </Container>
  </>
  )
}
