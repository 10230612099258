import React, { useEffect } from 'react';
import  { useFramework } from '../context/FrameworkContext'; // Access Framework Context
import { Table } from 'react-bootstrap'; // Use Bootstrap Table for formatting


const AllFrameworksReport = () => {
  const { userFrameworks, getFrameworks } = useFramework();

  // Fetch all frameworks when the component is mounted
  useEffect(() => {
    getFrameworks();
  }, [getFrameworks]);

  return (
    
    <div className="all-frameworks-report">
      <h2>All Frameworks Report</h2>
      {userFrameworks.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Framework Name</th>
              <th>Description</th>
              <th>Roles</th>
            </tr>
          </thead>
          <tbody>
            {userFrameworks.map((framework, index) => (
              <tr key={framework._id}>
                <td>{index + 1}</td>
                <td>{framework.frameworkName}</td>
                <td>{framework.frameworkDescription}</td>
                <td>
                  {framework.roles.map((role) => (
                    <span key={role._id}>{role.name}, </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No frameworks available.</p>
      )}
    </div>
    
  );
};

export default AllFrameworksReport;
