import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../api/axios';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      setVariant('danger');
      return;
    }

    try {
      const response = await axios.post(`/auth/reset-password/${token}`, { newPassword });
      setMessage(response.data.message);
      setVariant('success');
    } catch (error) {
      setMessage(error.response?.data?.message || 'Error resetting password');
      setVariant('danger');
    }
  };

  return (
    <Row>
      <Col lg={4} md={4} sm={12}>
        <Form onSubmit={handleSubmit}>
          {/* New Password */}
          <Form.Group controlId="formNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>

          {/* Confirm Password */}
          <Form.Group controlId="formConfirmPassword" className="mt-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3 appButton">
            Reset Password
          </Button>

          {/* Display message */}
          {message && <Alert variant={variant} className="mt-3">{message}</Alert>}
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
