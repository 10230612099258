import React, { useState } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from '../assets/logoShape.png';
import {FaGoogle,FaFacebook} from 'react-icons/fa'
import { useAuth } from '../context/AuthContext';
import './SignIn.css';

export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login, isAuthenticated } = useAuth();
    const [message, setMessage] = useState({ success: false, message: '' });
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/frameworks";

    const handleSubmit = async (event) => {
        event.preventDefault();
        let result = await login({ email, password, provider: 'local' });
        console.log(result);
        setMessage(result.message);

        if (result?.success === true) navigate(from, { replace: true });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col sm={6} xs={8} lg={4} md={5} className='authenticationContainer'>
                    <div style={{ textAlign: 'center' }}>
                        <h3 style={{ margin: '20px' }}>
                            <img src={Logo} alt='Edu Framework logo' className="logoShape" /> Sign In
                        </h3>
                    </div>

                    {message.message?.length > 0 && (
                        <Alert className={message.success === true ? "alert-success" : "alert-danger"}>
                            <span>{message.message}</span>
                        </Alert>
                    )}

                    <Form className='mp-3 authenticationForm' onSubmit={handleSubmit}>
                        <Form.Group className='mb-2'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                onChange={handleEmailChange}
                                type="email"
                                placeholder='Email'
                            />
                        </Form.Group>
                        <Form.Group className='mb-2'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                onChange={handlePasswordChange}
                                type="password"
                                placeholder='Password'
                            />
                        </Form.Group>

                        <div className='d-grid gap-2 mt-5'>
                            <Button className='appButton' variant="primary" type="submit">Submit</Button>
                        </div>
                        <div className="forgotPassword"><Link to="/forgot-password">Forgot Password</Link></div>
                    </Form>

                    <div style={{ textAlign: 'center', fontSize: 'medium' }}>
                        <p>------ Or ------</p>
                        <p><button  className="appRoundedCornersLightButton" onClick={()=>login({provider:"google"})}><FaGoogle className="logoShape"/> Login with Google</button></p>
                        <p><button className="appRoundedCornersLightButton" onClick={()=>login({provider:"facebook"})}><FaFacebook className="logoShape"/> Login with Facebook</button></p>
                        
                        <p></p>
                        <p>You don't have an account <Link to="/signup">Sign Up</Link></p>
                    </div>
                </Col>
            </Row>
        </>
    );
}
