import React from 'react'
import './Competency.css'
import { useFramework } from '../context/FrameworkContext'

export default function Competency(props) {
 
  
  const {setSelectedCompetency,setTooltipPosition,setTooltipIsVisible} = useFramework();
  const handleMouseOver=(event)=>{
        setSelectedCompetency(props.theCompetencyInfo);
        setTooltipPosition({x:event.pageX, y:event.pageY,direction:(event.pageX>window.innerWidth/2)?'left':'right'}) 
       
        setTooltipIsVisible('visible');
        
  }  
  const handleMouseOut=(event)=>{
        setTooltipIsVisible('hidden');
    
   }


   
  return (

    <div className="competency" style={{backgroundColor:props.color,borderColor:props.borderColor}}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
    
    
    >
     
    </div>     
  )
}
