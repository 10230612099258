import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaFileSignature } from 'react-icons/fa';
import termsImage from '../assets/terms.png' ;
export default function TermsOfService() {
    return (
        <Container>
            <p className="appPageIcon"><FaFileSignature /></p>
            <p className="appPageTitle"> | Terms of Service</p>
            <Row>
                <Col xs={12} md={6}>
                    <h2>Terms of Service</h2>
                    <p>Last updated: [Date]</p>

                    <h3>1. Introduction</h3>
                    <p>Welcome to EduFramework ("Company", "we", "our", "us")! These Terms of Service ("Terms", "Terms of Service") govern your use of our web pages located at <a href="https://eduframework.ca">https://eduframework.ca</a> operated by Logi Owl Content Creators Canada Inc.</p>
                    
                    <h3>2. Accounts</h3>
                    <p>When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
                    
                    <h3>3. Termination</h3>
                    <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                    
                    <h3>4. Content</h3>
                    <p>Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>
                    
                    <h3>5. Prohibited Uses</h3>
                    <p>You may use the Service only for lawful purposes and in accordance with the Terms. You agree not to use the Service:</p>
                    <ul>
                        <li>In any way that violates any applicable national or international law or regulation.</li>
                        <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                        <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter", "spam", or any other similar solicitation.</li>
                    </ul>
                    
                    <h3>6. Limitation of Liability</h3>
                    <p>In no event shall Logi Owl Content Creators Canada Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                    
                    <h3>7. Changes</h3>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                    
                    <h3>8. Contact Us</h3>
                    <p>If you have any questions about these Terms, please contact us at:</p>
                    <ul>
                        <li>Email: <a href="mailto:info@eduframework.ca">info@eduframework.ca</a></li>
                        <li>Tel: (587) 287-3005</li>
                    </ul>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <Image src={termsImage} alt="Terms of Service" fluid />
                </Col>
            </Row>
        </Container>
    );
}
