import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './CompetencyLegend.css';
import { useFramework } from '../context/FrameworkContext';

export default function CompetencyLegend() {
  const { competencySettings, coloredBy } = useFramework();

  // Choose the legends based on the current color coding selection (type, level, or status)
  const legends = competencySettings[coloredBy] || [];

  return (
    <div className="competencyLegend">
      <h5>Competency Legend</h5>
      <Row>
        {legends.map((legend, index) => (
          <Col xs={6} sm={4} md={3} lg={2} key={index} className="legendItem">
            <div className="legendColor" style={{ backgroundColor: legend.color }}></div>
            <span className="legendLabel">{legend.label}</span>
          </Col>
        ))}
      </Row>
    </div>
  );
}
