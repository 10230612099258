import React, {  useState } from 'react';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
import './ChangePassword.css';
import { useAuth } from '../context/AuthContext';

const ChangePassword = () => {
  const {changePassword}=useAuth()
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState({ text: '', success: null });
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage({ text: 'New password and confirm password do not match.', success: false });
      return;
    }

    
        
      const result=await changePassword(currentPassword,newPassword);
      if (result.success) {
      setMessage({ text: result.message || 'Password changed successfully.', success: true });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      } else {
      setMessage({ text: result?.message || 'Something went wrong, password was not changed.', success: false });
      };
  
    };
  return (
    <Row>

    <Col>
    {message.text && (
      <Alert variant={message.success ?  'success': 'danger'}>
        {message.text}
      </Alert>
    )}
    <Form onSubmit={handleSubmit} className=" changePasswordForm">
      <Form.Group controlId="currentPassword">
        <Form.Label>Current Password</Form.Label>
        <Form.Control
          type="password"
          value={currentPassword}
          name="currentPassword"
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="newPassword">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          value={newPassword}
          name="newPassword"
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="confirmNewPassword">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-3 appButton">
        Change Password
      </Button>

     
    </Form>
    </Col>
    </Row>
    
  );
};

export default ChangePassword;