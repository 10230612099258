import React from 'react';
import { useAuth } from '../context/AuthContext'; // Adjust the path to your AuthContext
import { Link } from 'react-router-dom';

const RequirePlan = ({ children, allowedPlans,message,showInOverlayBox=false }) => {
  // Use useAuth to get the current user's plan
  const { user } = useAuth();

  // Check if the user is authenticated and has a plan
  if (user && allowedPlans.includes(user.currentPlan)) {
    // Render the children if the user's current plan is allowed
    return <>{children}</>;
  }

  // Otherwise, render null (you can also render a message or redirect if needed)
  if (showInOverlayBox) {
    return <div className="overlay-box">{message || null} <Link to="/purchase-plan">Upgrade your plan</Link></div>;
  }
  return <>{message || null} <Link to="/purchase-plan">Upgrade your plan</Link></>; 
};

export default RequirePlan;
