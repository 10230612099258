import React,{useState,useEffect} from 'react'
import { Form,Row,Col} from 'react-bootstrap';
import FunctionEditor from './FunctionEditor';
import {FaRegMinusSquare,FaRegPlusSquare, FaCubes,FaTrashAlt} from 'react-icons/fa'
import './RoleEditor.css'
import { useFramework } from '../context/FrameworkContext';
import Message from './Message';
export default function RoleEditor(props) {
  const {addEmptyFunction,changeSingleValue,setMessage,message,removeRole}=useFramework();
  const [isExpanded,setIsExpanded]=useState(false)
  const handleDeleteRole=()=>{
    setMessage(
    { header:"Deleting a Role",
      text:"Are yuo sure you want to edit this role and all its functions and related competencies",
      variant:'warning',
      isVisibile:true,
      buttons:[{text:"Delete Role",action:removeTheRole},{text:"Cancel",action:hideTheMessage}]
    }
    )
  }
  const hideTheMessage=()=>{
    setMessage({header:"",text:"",variant:"",buttons:[],isVisible:false})
  }
  const removeTheRole=()=>{
    removeRole(props.id)
    hideTheMessage();
    
  }

  
  const handleAddEmptyFunction=(event)=>{
    addEmptyFunction(props.id)
  }
 
  const handleInputChange=(event)=>{
    let fieldValue=event.target.value;
    let fieldName=event.target.name;
    
    changeSingleValue(fieldName,fieldValue,props.id);
  }
  const handleToggleExpand=()=>{
    setIsExpanded(!isExpanded);
  }
  return (
    <>
    <div>
    <Row>
      <Col lg="11" xs="11" sm="11" md="11">
      <Row>
          <Col lg="2" md="3" xs="3" sm="4" className="appEditorLabel roleEditorLabel ">
        
          <Form.Label><div className="appHorizontalLine"></div>Name</Form.Label>
          </Col>
          <Col>
          <Form.Control 
                        name="name" 
                        type="text" 
                        defaultValue={props.name}
                        onChange={handleInputChange}
                        ></Form.Control>
          </Col>
      </Row>
      <Row>
          <Col lg="2" md="3" xs="3" sm="4" className="appEditorLabel roleEditorLabel ">
          <Form.Label>Description&nbsp;&nbsp;</Form.Label>
          </Col>
          <Col>
          <Form.Control name="description"
                        type="text" 
                        defaultValue={props.description}
                        onChange={handleInputChange}
                        ></Form.Control>
          </Col>
      </Row> 
      </Col>
      <Col lg="1" md="1" sm="1" xs="1">
        <div className="appIcon" onClick={handleDeleteRole}>
        <FaTrashAlt />
        </div>
      </Col>
    </Row>
    </div>
    <div className="appTreeLines">
     {<div className="expandCollapseButton" onClick={handleToggleExpand}>{isExpanded?<FaRegMinusSquare/>:<FaRegPlusSquare/>} <FaCubes/><b> Functions ({props.functions.length})</b></div>}
      <div className="appIdented" style={{display:isExpanded?'block':'none'}}>
        <div >
          {props.functions.map((theFunction)=>{
            return(
              <FunctionEditor
                key={theFunction._id}
                id={theFunction._id}
                roleID={props.id}
                name={theFunction.name}
                description={theFunction.description}
                competencies={theFunction.competencies}
              
              
              ></FunctionEditor>
            )
          })}
           <button  onClick={handleAddEmptyFunction} className="appEditorButtons functionEditorLabel" style={{left:'-30px',top:'20px'}}>+<FaCubes/></button>
         
        </div>
       
      </div>
      </div>
      <div style={{height:'20px'}}></div>

      <p></p>
          
    </>
  )
}
