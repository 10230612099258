import React, { useRef } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import ContactBadge from './ContactBadge';
import './ProgramView.css';
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
export default function ProgramView(props) {
  
  const programRef = useRef();
 
  const handlePrint = useReactToPrint({
    content: () => programRef.current,
    documentTitle: props.data?.name || 'Program Report',
  });
  if (!props.data) {
    return <><h1 className="programInformation">Program Information</h1><p>No parogram selected</p></>; // Handle undefined or null data gracefully
  }
console.log(props.data?.relatedFrameworks)
  return (
    <div  ref={programRef}>
      <Row>
        <Col lg="12" md="12" sm="12" sx="12" className="programInformation">
          Program Information <button title="Print" onClick={handlePrint} className="btn btn-primary printButton" style={{ marginTop: '20px' }}><FaPrint /></button>
        </Col>
      </Row>

      {props.data.name !== undefined ? (
        <div className="viewContainer">
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Program Name</Col>
            <Col className="viewValue">{props.data?.name}</Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Program Description</Col>
            <Col className="viewValue">{props.data?.description}</Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Level</Col>
            <Col className="viewValue">{props.data?.level}</Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Stakeholders:</Col>
            <Col>
              {props.data.stakeholders?.map((stakeholder, index) => {
                return (
                  <ContactBadge
                    key={index}
                    data={stakeholder}
                    columnsNames={[
                      { label: 'Full Name', columnDBName: 'fullName' },
                      { label: 'Organization', columnDBName: 'organization' },
                      { label: 'Email', columnDBName: 'email' },
                      { label: 'Tel', columnDBName: 'phoneNumber' },
                      { label: 'Role', columnDBName: 'role' },
                      { label: 'Mapping', columnDBName: 'mapping' }
                    ]}
                  />
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col lg="11" md="11" sm="11" sx="11" className="viewLabel">Audience Profile</Col>
          </Row>
          <Row>
            <Col lg="11" md="11" sm="11" sx="11">
              <Row>
                <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Audience</Col>
                <Col className="viewValue">{props.data.audience?.who}</Col>
              </Row>
              <Row>
                <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Background Knowledge</Col>
                <Col className="viewValue">{props.data.audience?.backgroundKnowledge}</Col>
              </Row>
              <Row>
                <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Technology Comfort Level:</Col>
                <Col>
                  <input type="range" min="0" max="5" value={props.data.audience?.technologyComfortLevel} step="1" readOnly />
                  <Badge className="bigBadge" bg="secondary">{props.data.audience?.technologyComfortLevel}</Badge>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Math Comfort Level:</Col>
                <Col>
                  <input type="range" min="0" max="5" value={props.data.audience?.mathComfortLevel} step="1" readOnly />
                  <Badge className="bigBadge" bg="secondary">{props.data.audience?.mathComfortLevel}</Badge>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Age (From-To):</Col>
                <Col>
                  <input type="range" min="1" max="110" value={props.data.audience?.ageFrom} step="1" readOnly />
                  <Badge className="bigBadge" bg="secondary">{props.data.audience?.ageFrom}</Badge>
                  <input type="range" min="1" max="110" value={props.data.audience?.ageTo} step="1" readOnly />
                  <Badge className="bigBadge" bg="secondary">{props.data.audience?.ageTo}</Badge>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Preferred Delivery Method</Col>
                <Col className="viewValue">{props.data.audience?.preferedDeliveryMethod}</Col>
              </Row>
              <Row>
                <Col lg="12" md="12" sm="12" sx="12" className="viewLabel">
                Created to fully or partially cover the competencis of the following frameworks
                </Col>
                <Col lg="12" md="12" sm="12" sx="12" className="viewValue">
                {props.data.relatedFrameworks?.map((relatedFramework, index) => {
                  return( 
                  <div key={index+"framwwork"}>
                    <div className="programFrameworkName">{relatedFramework.frameworkName}</div>
                    <div className="programFrameworkDescription">{relatedFramework.frameworkDescription}</div>
                  
                  </div>




                  );
                })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col lg="11" md="11" sm="11" sx="11" className="viewLabel">Program's Courses</Col>
                  </Row>
                  <Row>
                    {props.data.courses?.map((course, index) => {
                      return (
                        <Col lg="4" md="5" sm="9" sx="12" key={index}>
                          <div className="programCourseBlock">
                            <div className="courseCode">{course.code}</div>
                            <div className="courseName">{course.name}</div>
                            <div>{course.description}</div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row>
            <Col>Please select a program</Col>
          </Row>
        </div>
      )}

      
    </div>
  );
}
