import React, {useEffect, useState} from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import {FaTrashAlt} from 'react-icons/fa'

import {useCourse} from '../context/CourseContext'

import LearningObjectiveEditor from './LearningObjectiveEditor';
import { useSettings} from '../context/SettingContext';

export default function Courseditor(props) {
 
  const {selectedCourse,setSelectedCourse}=useCourse();

  const {getSettingByName,settingData}=useSettings();
  
  useEffect(  ()=>{
    if (!settingData.deliveryMethods) {
      getSettingByName("deliveryMethods");
    }
    
    
    

  },[])
  const handleInputChange=(event)=>{
    let value=event.target.value;
   
    let updatedUserCourse={...selectedCourse};
    let schema=updatedUserCourse;
    let path=event.target.name;
    let nestedItemsList=path.split('.');
    let nestedLevels=nestedItemsList.length;
    for (let i=0;i<nestedLevels-1;i++){
      let element=nestedItemsList[i];
      if (!schema[element]) schema[element]={}
      schema=schema[element];
    }
    schema[nestedItemsList[nestedLevels-1]]=value
    setSelectedCourse({...updatedUserCourse})
    console.log(selectedCourse);

}

//LEARNING OBJECTIVES
///////////////////////

const handleLearningObjectivesChange=(learningObjective,index)=>{
  //let newLearningObjectives=selectedCourse.learningObjectives;
   
  let updatedSelectedCourse={...selectedCourse}
  updatedSelectedCourse.learningObjectives[index]=learningObjective;
  setSelectedCourse({...updatedSelectedCourse})
  console.log(selectedCourse)
}
const handleAddLearningObjective=()=>{
  let updatedSelectedCourse={...selectedCourse}
  updatedSelectedCourse.learningObjectives.push({domain:"",level:"",verb:"",condition:"",masteryLevel:"",text:"",activities:[]})
  setSelectedCourse(updatedSelectedCourse)
}
const handleDeleteLearningObjective=(indexToDelete)=>{
  let updatedSelectedCourse={...selectedCourse}
  updatedSelectedCourse.learningObjectives.splice(indexToDelete,1)
  setSelectedCourse(updatedSelectedCourse)

 }
/////////////////////////////////////

//LEARNING GOALS
////////////////////////
 const handleDeleteLearningGoal=(indexToDelete)=>{
  let updatedSelectedCourse={...selectedCourse}
  updatedSelectedCourse.goals.splice(indexToDelete,1)
  setSelectedCourse(updatedSelectedCourse)

 }
 const handleAddLearningGoal=()=>{
   let updatedSelectedCourse={...selectedCourse}
   updatedSelectedCourse.goals.push("")
   setSelectedCourse(updatedSelectedCourse)
 }


 
  return (
      <>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel  frameworkEditorLabel ">
        <Form.Label >Course Name</Form.Label>
      </Col>
        <Col>
            <Form.Control   name="name"
            type="text" 
            defaultValue={selectedCourse.name}
            onChange={handleInputChange} 
            ></Form.Control> 
        </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Course Code</Form.Label>
      </Col>
      <Col>
        <Form.Control   name="code"
        type="text" 
        defaultValue={selectedCourse.code}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Description</Form.Label>
      </Col>
      <Col>
        <Form.Control name="description"
        type="text" 
        defaultValue={selectedCourse.description}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Delivery Method</Form.Label>
      </Col>
      <Col>
        <Form.Control as="select" name="deliveryMethod"
        
        defaultValue={selectedCourse.deliveryMethod}
        onChange={handleInputChange} 
        >
          {settingData?.deliveryMethods?.map((item,index)=>{
          return(
            
            <option key={index} value={item}>{item}</option>
            )
          
       })}
        </Form.Control>
      </Col>
    </Row>
    <hr/>
    <Row>
      <Col lg="11" md="11" xs="11" sm="11" className="appEditorLabel frameworkEditorLabel ">Learning Goals
      <button title="Add Item" className="addDataItemButton" onClick={handleAddLearningGoal}>+</button>
      </Col>
    </Row>

    {selectedCourse.goals?.map((goal,index)=>{
      return(
        <Row>
          <Col lg="11" sm="11" md="11" xs="11">
          <Form.Control name={"goals."+index}
            type="text" 
            onChange={handleInputChange} 
            value={goal}
            ></Form.Control>
          </Col>
          <Col lg="1" sm="1" md="1" xs="1">
          <div className="appIcon"> <FaTrashAlt onClick={(event)=>handleDeleteLearningGoal(index)}/> </div>
          </Col>
          
        </Row>
        
      )
    })
    }
    <hr/>
    

   
   
    
    <Row>
      <Col lg="11" xs="11" sm="11" md="11" className="appEditorLabel frameworkEditorLabel ">Learning Objectives
      <button title="Add Item" className="addDataItemButton" onClick={handleAddLearningObjective}>+</button>
      </Col>
    </Row>
    
    <Row>
      <Col>
      {selectedCourse.learningObjectives.map((learningObjective,index)=>{
        return(
        <Row>
        <Col>
          <LearningObjectiveEditor
              loKey={index}
              learningObjective={learningObjective}
              onSave={handleLearningObjectivesChange}
       />
        </Col>
        <Col lg="1" xs="1" sm="1" md="1">

        <div className="appIcon"> <FaTrashAlt onClick={(event)=>handleDeleteLearningObjective(index)}/> </div>
        </Col>
        </Row>
        )
      })}
      
      </Col>
     
    </Row>
    </>
  )
}
