import React, { useState } from "react";
import { Button,Col } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import './SideMenuToggle.css'
export default function SideMenuToggle({children}) {
    const [isVisible, setIsVisible] = useState(true);

  const toggleCollapse = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
   
    {isVisible?<>{children} <Col xs="1" md="1" lg="1" className="sideMenuToggle">
        <Button className="toggleButton" onClick={toggleCollapse}>{isVisible ? <FaArrowLeft />:<FaArrowRight /> }</Button>
    </Col></>: <Col xs="1" md="1" lg="1" className="sideMenuToggle">
        <Button className="toggleButton" onClick={toggleCollapse}>{isVisible ? <FaArrowLeft />:<FaArrowRight /> }</Button>
    </Col>}
    </>
  )
}
