import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useCertificate } from '../context/CertificateContext';
import { useFramework } from '../context/FrameworkContext';
import { useCourse } from '../context/CourseContext';
import { useProgram } from '../context/ProgramContext';
import { useSettings } from '../context/SettingContext';

export default function CertificateEditor(props) {
  const { selectedCertificate, setSelectedCertificate } = useCertificate();
  const { userFrameworks, getFrameworks } = useFramework();
  const { userCourses, getUserCourses } = useCourse();
  const { userPrograms, getUserPrograms } = useProgram();
  const { settingData, getSettingByName } = useSettings();

  const [optionsList, setOptionsList] = useState([]); // Dynamic list of options based on selection

  useEffect(() => {
    // Fetch all necessary data
    getFrameworks();
    getUserCourses();
    getUserPrograms();
    getSettingByName('levels'); // Assuming 'levels' is fetched from settings context
  }, []);

  const handleInputChange = (event) => {
    let value = event.target.value;
    let updatedCertificate = { ...selectedCertificate };
    let schema = updatedCertificate;
    let path = event.target.name;
    let nestedItemsList = path.split('.');
    let nestedLevels = nestedItemsList.length;

    for (let i = 0; i < nestedLevels - 1; i++) {
      let element = nestedItemsList[i];
      if (!schema[element]) schema[element] = {};
      schema = schema[element];
    }
    schema[nestedItemsList[nestedLevels - 1]] = value;
    setSelectedCertificate({ ...updatedCertificate });
  };

  const handleCertificateForChange = (e) => {
    const selectedValue = e.target.value;
    handleInputChange(e);

    switch (selectedValue) {
      case 'Framework':
        setOptionsList(userFrameworks.map((fw) => ({ id: fw._id, name: fw.frameworkName })));
        break;
      case 'Course':
        setOptionsList(userCourses.map((course) => ({ id: course._id, name: course.name })));
        break;
      case 'Program':
        setOptionsList(userPrograms.map((program) => ({ id: program._id, name: program.name })));
        break;
      case 'Level':
        setOptionsList(settingData.levels.map((level) => ({ id: level._id, name: level.name })));
        break;
      default:
        setOptionsList([]);
    }
  };

  return (
    <>
      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Certificate Title</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="title"
            type="text"
            defaultValue={selectedCertificate.title}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Issued By</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="issuedBy"
            type="text"
            defaultValue={selectedCertificate.issuedBy}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Certificate for</Form.Label>
        </Col>
        <Col>
          <Form.Control
            as="select"
            name="certificateFor"
            defaultValue={selectedCertificate.certificateFor}
            onChange={handleCertificateForChange}
          >
            <option value="" selected>Please select</option>
            <option value="Framework">Framework</option>
            <option value="Program">Program</option>
            <option value="Course">Course</option>
            <option value="Level">Level</option>
            
          </Form.Control>
        </Col>
      </Row>

      {optionsList.length > 0 && (
        <Row>
          <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
            <Form.Label>Select {selectedCertificate.certificateFor}</Form.Label>
          </Col>
          <Col>
            <Form.Control
              as="select"
              name="selectedID"
              defaultValue={selectedCertificate.selectedID || ''}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              {optionsList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Achievement Description</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="achievementDescription"
            type="text"
            defaultValue={selectedCertificate.achievementDescription}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Validity Duration</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="validityDuration"
            type="text"
            defaultValue={selectedCertificate.validityDuration}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
    </>
  );
}
