import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FrameworkContextprovider from '../context/FrameworkContext';
import CourseContextprovider from '../context/CourseContext';
import CertificateContextprovider from '../context/CertificateContext';
import ProgramContextprovider from '../context/ProgramContext';
import SettingContextprovider from '../context/SettingContext';
const ReportLayout = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      <div className="pageContainer">
      <CertificateContextprovider>
      <CourseContextprovider>
      <ProgramContextprovider>
      <FrameworkContextprovider>
        <SettingContextprovider>

        {children}

        </SettingContextprovider>
      </FrameworkContextprovider>
      </ProgramContextprovider>
      </CourseContextprovider>
      </CertificateContextprovider>
      </div>
      <Footer />
    </div>
  );
};

export default ReportLayout;