import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RequireAuth = ({ children, ...rest }) => {
  const { user, isAuthenticated, hasCheckedSession, checkSession, loading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!hasCheckedSession) {
      checkSession();
    }
  }, [hasCheckedSession, checkSession]);

  if (loading || !hasCheckedSession) {
    return (
      <div className="text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Check if the user is authenticated
  if (!isAuthenticated) {
    console.log("Not logged in");
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  // Check if the user is authenticated but not activated
  if (isAuthenticated && user && !user.isActive) {
    console.log("Account is not activated");
    return <Navigate to="/activation-required" state={{ from: location }} replace />;
  }
  

  console.log("i PASSED CHECK ON THE CLIENT SIDE");
  return children;
};

export default RequireAuth;
