import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Framework from '../components/Framework';


import FrameworkContextprovider, { useFramework } from '../context/FrameworkContext';
import SharedFrameworkView from '../components/SharedFrameworkView';

// Use `useLocation` to access the current URL and query parameters


export default function EmbedFrameworkPage() {
   
  return (
    <FrameworkContextprovider>
    <div><SharedFrameworkView/></div>
    </FrameworkContextprovider>

  )
}
