import React,{useState, } from 'react'
import PropTypes from 'prop-types'
import { FormSelect, Form, Row, Col } from 'react-bootstrap';
import { FaFilter,FaTrashAlt,FaRegTimesCircle,FaHandshake  } from 'react-icons/fa';
import './FilterSelector.css'
function FilterSelector(props) {
    const [isVisible, setIsVisible] = useState(false);
    const[newFilter,setNewFilter]=useState({columnDBName:props.columnsNames[0],value:""})
    const [listFilters,setListFilters]=useState([])
    const handleDeleteAllFilters=()=>{
        setListFilters([])
        props.getFilters([])
        setIsVisible(false);
    }
    const handleInputChange=(event)=>{
        let value=event.target.value;
        setNewFilter({...newFilter,value:value})
    }
    const handleColumnChange=(event)=>{
        setNewFilter({...newFilter,columnDBName:event.target.value})
    }
    const handleFilterClick = () => {
        setIsVisible(!isVisible);
    };
    const handleDeleteFilter=(index)=>{
        let newFilters=[...listFilters];
        newFilters.splice(index,1);
        setListFilters(newFilters)
    }
    const handleAddFilter=()=>{
        let newFilters=[...listFilters];
        newFilters.push(newFilter)
        setListFilters(newFilters)
        setNewFilter({...newFilter,value:""})
    }
    const handleApplyFilters=()=>{
        
        props.getFilters(listFilters)
        setIsVisible(false);
    }

    const handleCancel=()=>{
        setIsVisible(false)
    }
    return (
        <div>
            <button title="Filter"  className="filterDataButton" onClick={handleFilterClick}><FaFilter /></button>
            {listFilters.length>0 && <button  title="Delete all filters" className="filterDataButton" onClick={handleDeleteAllFilters}><FaRegTimesCircle /></button>}
            {isVisible && (
                <div className="filterContainer">
                    <Row>
                    <Col lg="3" md="3" sm="3" sx="6">
                            <Form.Label>The Value of </Form.Label>
                        </Col>
                        <Col>
                            <Form.Select onChange={handleColumnChange}>
                                {props.columnsNames.map((columnName,index) => (
                                    <option key={index} value={columnName}>{props.columnsHeaders[index]}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="3" sx="6">
                            <Form.Label>Equal or include </Form.Label>
                        </Col>
                        <Col lg="9" md="9" sm="9" sx="6">
                            <Form.Control 
                                name="filterValue"
                                type="text"
                                value={newFilter.value}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button className="filterEditorButton" style={{width:"100%"}} onClick={handleAddFilter}>+ Add Filter</button>
                           
                            </Col>
                    </Row>
                    <Row>
                        <Col>
                            {listFilters.map((filter,index) => (
                                <Row>
                                    <Col>{filter.columnDBName}</Col>
                                    <Col>{filter.value}</Col>
                                    <button title="delete"  key={"deleteFilterID"+index} className="appButtonIcons " onClick={(event)=>handleDeleteFilter(index)}><FaTrashAlt /></button>
                                    <col></col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button className="filterEditorButton" onClick={handleApplyFilters}><FaHandshake /> Apply</button><button className="filterEditorButton" onClick={handleCancel}><FaRegTimesCircle /> Cancel</button>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}


    


export default FilterSelector;
