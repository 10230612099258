import React, { useEffect } from 'react';
import { useCourse } from '../context/CourseContext'; // Assume context for courses exists
import { Table } from 'react-bootstrap'; // Use Bootstrap Table for formatting

const AllCoursesReport = () => {
  const { userCourses, getUserCourses } = useCourse();

  // Fetch all courses when the component is mounted
  useEffect(() => {
    getUserCourses(); // Function to fetch all courses
  }, [getUserCourses]);

  return (
    <div className="all-courses-report">
      <h2>All Courses Report</h2>
      {userCourses?.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Course Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {userCourses.map((course, index) => (
              <tr key={course._id}>
                <td>{index + 1}</td>
                <td>{course.name}</td>
                <td>{course.description}</td>
                <td>{course.type}</td>
                <td>{course.duration}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No courses available.</p>
      )}
    </div>
  );
};

export default AllCoursesReport;
