import React from 'react'
import ProfileManager from '../components/ProfileManager'
import { Container } from 'react-bootstrap'
import { FaUserCog } from 'react-icons/fa'
import SettingContextprovider from '../context/SettingContext'
export default function ProfileManagerPage() {
  return ( 
  
  <>
  <SettingContextprovider>
    <Container>
      <p className="appPageIcon"><FaUserCog /></p>
      <h2 className="appPageTitle"> | Profile Manager</h2>
      
      <div>
          <ProfileManager/>

      </div>
    </Container>
    </SettingContextprovider>
    </>
  )
}
