import React from 'react'
import {Form,Row,Col } from 'react-bootstrap';
import { useFramework } from '../context/FrameworkContext';
import './ColorCodeSelector.css'
export default function ColorCodeSelector() {
  const {setColoredBy}=useFramework();
  const handleColorByChange=(event)=>{
    setColoredBy(event.target.value)
  }
  return (
<>
  <Row className="colorCodeSelector"> 
    <br/>
    <Col  className="colorCodeSelectorLabel"><b>Color-code by Compentency</b></Col>
    <Col>
    <Form.Select className="colorCodeSelect"
                onChange={handleColorByChange}
    >
        <option value="type">Type</option>
        <option value="level">Level</option>
        <option value="required">Required</option>
        
    </Form.Select>
    </Col>
  </Row>
</>
)
}