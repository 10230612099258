import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useSettings } from '../context/SettingContext'
import { FaTrashAlt } from 'react-icons/fa'
import ActivityEditor from './ActivityEditor'
import "./LearningObjectiveEditor.css";

export default function LearningObjectiveEditor(props) {
  const { settingData, getSettingByName } = useSettings();
  const [selectedLearningObjectiveData, setSelectedLearningObjectiveData] = useState(props.learningObjective)

  const [domains, setDomains] = useState([])
  const [levels, setLevels] = useState([])
  const [verbs, setVerbs] = useState([])

 
  useEffect(() => {
    if (!settingData?.domains) {
      getSettingByName("domains");
    }
    if (!settingData?.masteryLevels) {
      getSettingByName("masteryLevels");
    }
  }, [getSettingByName]);


  useEffect(() => {
    if (settingData?.domains) {
      setDomains(settingData.domains);
    }
  }, [settingData]);

  useEffect(() => {
    const selectedDomain = domains.find(domain => domain.name === selectedLearningObjectiveData.domain);
    if (selectedDomain) {
      setLevels(selectedDomain.levels);
      const selectedLevel = selectedDomain.levels.find(level => level.name === selectedLearningObjectiveData.level);
      if (selectedLevel) {
        setVerbs(selectedLevel.verbs);
      } else {
        setVerbs([]);
      }
    } else {
      setLevels([]);
      setVerbs([]);
    }
  }, [selectedLearningObjectiveData.domain, selectedLearningObjectiveData.level, domains]);

  const handleDomainNameChange = (event) => {
    const updatedData = { ...selectedLearningObjectiveData, domain: event.target.value, level: '', verb: '', text: '' };
    setSelectedLearningObjectiveData(updatedData);
    props.onSave(updatedData, props.loKey);
    console.log(props.loKey)
  };

  const handleLevelNameChange = (event) => {
    const updatedData = { ...selectedLearningObjectiveData, level: event.target.value, verb: '' };
    setSelectedLearningObjectiveData(updatedData);

    props.onSave(updatedData, props.loKey);
  };

  const handleVerbChange = (event) => {
    const updatedData = { ...selectedLearningObjectiveData, verb: event.target.value };
    setSelectedLearningObjectiveData(updatedData);
    props.onSave(updatedData, props.loKey);
  };

  const handleLOTextChange = (event) => {
    const updatedData = { ...selectedLearningObjectiveData, text: event.target.value };
    setSelectedLearningObjectiveData(updatedData);
    props.onSave(updatedData, props.loKey);
  };
  const handleConditionChange = (event) => {
    const updatedData = { ...selectedLearningObjectiveData, condition: event.target.value };
    setSelectedLearningObjectiveData(updatedData);
    props.onSave(updatedData, props.loKey);
  };
  const handleMasteryLevelChange = (event) => {
    const updatedData = { ...selectedLearningObjectiveData, masteryLevel: event.target.value };
    setSelectedLearningObjectiveData(updatedData);
    console.log(updatedData)
    props.onSave(updatedData, props.loKey);
  };
  const handleDeleteActivity = (activityKey) => {
    alert(activityKey)
    const updatedActivities = selectedLearningObjectiveData.activities;
    updatedActivities.splice(activityKey, 1);
    const updatedData = { ...selectedLearningObjectiveData, activities: updatedActivities };
    setSelectedLearningObjectiveData(updatedData);
    props.onSave(updatedData, props.loKey);
  };
  const handleAddActivity = () => {
   
    const updatedData = { ...selectedLearningObjectiveData, activities: [...selectedLearningObjectiveData.activities, { name:'',type: '', phase: '', description: '', content:'' }] };
    setSelectedLearningObjectiveData(updatedData);
    props.onSave(updatedData, props.loKey);
}
const handleActivityChange=(activity,index)=>{
  const updatedActivities=[...selectedLearningObjectiveData.activities]
    updatedActivities[index].name=activity.name
    updatedActivities[index].type=activity.type
    updatedActivities[index].phase=activity.phase
    updatedActivities[index].description=activity.description
    updatedActivities[index].content=activity.content
    const updatedData={...selectedLearningObjectiveData,activities:updatedActivities}
    setSelectedLearningObjectiveData(updatedData)
    props.onSave(updatedData,props.loKey)
    console.log()
}


  return (

    <div className="learningObjectiveEditorBlock">
      <Row>
        <Col lg="11" sm="11" xs="11" md="11" >
          <Row>
            <Col lg="4" md="4" xs="12" sm="12">
              <strong>Bloom Domain</strong>
              <Form.Control as="select" onChange={handleDomainNameChange} value={selectedLearningObjectiveData.domain}>
                <option> </option>
                {domains?.map((domain, index) => {
                  return (
                    <option key={index} value={domain.name}>{domain.name}</option>
                  )
                })
                }
              </Form.Control>
            </Col>
            <Col lg="4" md="4" xs="12" sm="12">
              <strong>Bloom Level</strong>
              <Form.Control as="select" onChange={handleLevelNameChange} value={selectedLearningObjectiveData.level}>

                {levels?.map((level) => {
                  return (
                    <option value={level.name}>{level.name}</option>
                  )
                })
                }
              </Form.Control>
            </Col>

            <Col lg="4" md="4" xs="12" sm="12">
              <strong>Bloom Verb</strong>
              <Form.Control as="select" value={selectedLearningObjectiveData.verb}
                onChange={handleVerbChange}>
                <option value="">Not listed</option>
                {verbs?.map((verb) => {
                  return (
                    <option value={verb}>{verb}</option>
                  )
                })
                }
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col lg="8" md="8" xs="12" sm="12">
              <strong>Condition</strong>
              <Form.Control type="text" value={selectedLearningObjectiveData.condition} onChange={handleConditionChange}>

              </Form.Control>
            </Col>
            <Col lg="4" md="4" xs="12" sm="12">
              <strong>Mastery Level</strong>
              <Form.Control as="select" value={selectedLearningObjectiveData.masteryLevel}
                onChange={handleMasteryLevelChange}>

                {settingData?.masteryLevels?.map((masteryLevel) => {
                  return (
                    <option value={masteryLevel}>{masteryLevel}</option>
                  )
                })
                }
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12" sm="12">
              <strong>Description</strong>
              <Form.Control type="text" value={selectedLearningObjectiveData.text} onChange={handleLOTextChange}>

              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col lg="11" xs="11" sm="11" md="11" className="appEditorLabel frameworkEditorLabel ">Activities
            <button title="Add Activity" className="addDataItemButton" onClick={handleAddActivity}>+</button>
            </Col>
          </Row>
          <br/>
          {selectedLearningObjectiveData?.activities.map((activity, index) => {
            return(
          
          <Row>
            <Col lg="11" md="11" xs="11" sm="11">
              <ActivityEditor
                index={index}
                activity={activity}
                domain={selectedLearningObjectiveData.domain}
                level={selectedLearningObjectiveData.level}
                loText={selectedLearningObjectiveData.verb+' '+selectedLearningObjectiveData.text}
                onSave={handleActivityChange}
              />
              
            </Col>
            <Col lg="1" md="1" xs="1" sm="1">
              <div className="appIcon"> <FaTrashAlt onClick={(event)=>handleDeleteActivity(index)}/> </div>
            </Col>
          </Row>)})}
        
        
        </Col>
      </Row>
    </div>
  )
}
