import React from 'react'
import { Container } from 'react-bootstrap'
import PricingPlans from '../components/PricingPlans'
import { FaTags } from 'react-icons/fa'
import PlanContextProvider from '../context/PlanContext'

export default function PricingAndPlansPage() {
  return (
    <>
    <PlanContextProvider>
    <Container>
      <p className="appPageIcon"><FaTags/></p>
      <h2 className='appPageTitle'>| Pricing and Subscription Plans</h2>
      <PricingPlans/>
    </Container>
    </PlanContextProvider>
    </>
    
    
  )
}
