import React from 'react'
import {Col,Row} from 'react-bootstrap'
import SocialBar from './SocialBar'
import logoShape from '../assets/logoShape.png'
import {Link} from 'react-router-dom'
import './Footer.css'
export default function Footer() {
  return (
    <div className="footer">
      <Row>
        <Col>
          <img src={logoShape} alt="logo shape only" />
        </Col>
        <Col >
          <p><strong>Contact Information</strong></p>
          <p><Link to='contact-us'>Contact Us</Link></p>
          <p><Link to='mailto:info@eduframework.ca'>info@eduframework.ca</Link></p>
          <p><b>Tel</b>+1 (587)287-3005</p>
          <p><b>Fax</b>+1 (587)288-9574</p>

        </Col>
        
        <Col>
        <p><strong>Quick Links</strong></p>
        <p><Link to='/home'>Home</Link></p>
        <p><Link to='/pricing-and-plans'>Pricing & plans</Link></p>
        <p><Link to='/learning-resources'>Learning Resources</Link></p>
        <p><Link to='/about-us'>About Us</Link></p>
        </Col>
        <Col>
        <p><strong>Access</strong></p>
        <p><Link to='/signin'>Login</Link></p>
        <p><Link to='/signup'>Sign Up</Link></p>
        <p><Link to='/forgot-password'>Forgot Password</Link></p>
        </Col>
        <Col>
        <p><strong>Legal Information</strong></p>
        <p><Link to='/privacy-policy'>Privacy Policy</Link></p>
        <p><Link to='/terms-of-service'>Terms of Service</Link></p>
        <p><Link to='/cookies-policy'>Cookies Policy</Link></p>
        
        </Col>
      </Row>
      <Row>
        <Col sm='12' xs='12' lg='6'>
          <SocialBar
          twitter='https:google.com'
          youTube='https:google.com'
          linkedIn='https:google.com'
          instagram='https:google.com'
          facebook='https:google.com'
          pInterest='https:google.com'
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' sm='12' lg='12' >
          <p  align="center">All rights reserved to Logi Owl Contents Creators Canada inc. &copy;</p>
        </Col>
      </Row>
    </div>
  )
}
