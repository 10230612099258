import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaCookieBite } from 'react-icons/fa';
export default function CookiesPolicyPage() {
    return (
        <Container>
            <Row>
                <Col>
                
                    <p className="appPageIcon"><FaCookieBite /></p>
                    <h2 className="appPageTitle"> | Cookies Policy</h2>
                    <p>Last updated: [Date]</p>

                    <h2>1. Introduction</h2>
                    <p>Welcome to [Your Application Name] ("Company", "we", "our", "us")! This Cookies Policy explains what cookies are, how we use cookies, and your choices regarding cookies.</p>

                    <h2>2. What are cookies?</h2>
                    <p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.</p>

                    <h2>3. How [Your Application Name] uses cookies</h2>
                    <p>When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes:</p>
                    <ul>
                        <li><strong>To enable certain functions of the Service:</strong> We use cookies to remember your preferences and various settings.</li>
                        <li><strong>To provide analytics:</strong> We use cookies to analyze the use and performance of our website and services.</li>
                        <li><strong>To store your preferences:</strong> We use cookies to store your preferences and settings to enhance your experience on our website.</li>
                        <li><strong>For advertising:</strong> We use cookies to serve you with advertisements that may be relevant to you and your interests.</li>
                    </ul>

                    <h2>4. Types of cookies we use</h2>
                    <p>We use both session and persistent cookies on the Service, and we use different types of cookies to run the Service:</p>
                    <ul>
                        <li><strong>Essential cookies:</strong> We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.</li>
                        <li><strong>Preferences cookies:</strong> We may use preferences cookies to remember information that changes the way the Service behaves or looks, such as the "remember me" functionality of a registered user or a user's language preference.</li>
                        <li><strong>Analytics cookies:</strong> We may use analytics cookies to track information about how the Service is used so that we can make improvements. We may also use analytics cookies to test new advertisements, pages, features, or new functionality of the Service to see how our users react to them.</li>
                        <li><strong>Advertising cookies:</strong> These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</li>
                    </ul>

                    <h2>5. Third-party cookies</h2>
                    <p>In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</p>

                    <h2>6. Your choices regarding cookies</h2>
                    <p>If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>
                    <ul>
                        <li>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</a></li>
                        <li>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a></li>
                        <li>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></li>
                        <li>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/kb/PH21411?locale=en_US">https://support.apple.com/kb/PH21411?locale=en_US</a></li>
                    </ul>
                    <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>

                    <h2>7. More information about cookies</h2>
                    <p>You can learn more about cookies and the following third-party websites:</p>
                    <ul>
                        <li>AllAboutCookies: <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a></li>
                        <li>Network Advertising Initiative: <a href="http://www.networkadvertising.org/">http://www.networkadvertising.org/</a></li>
                    </ul>

                    <h2>8. Contact us</h2>
                    <p>If you have any questions about this Cookies Policy, please contact us         
                        <ul>
                            <li>By email: <a href="mailto:info@eduframework.ca">info@eduframework.ca</a></li>
                            <li>By phone number: +1 (587)287-3005</li>
                        </ul>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
