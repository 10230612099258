import React, { useState } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaUserCheck } from 'react-icons/fa';
import axios from '../api/axios';

const ActivationRequiredPage = () => {
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState(''); // 'success' or 'danger' for alert styling
  const navigate = useNavigate();

  const handleResendActivationEmail = async () => {
    try {
      setIsSending(true);
      setMessage('');
      setVariant('');

      // API call to resend activation link
      const response = await axios.post('/auth/resend-activation-email', {}, { withCredentials: true });
      
      // If successful, show success message
      setMessage('Activation email has been resent. Please check your email.');
      setVariant('success');
    } catch (error) {
      // If error occurs, show error message
      setMessage(error.response?.data?.message || 'Error resending activation email');
      setVariant('danger');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container>
        <p className="appPageIcon"><FaUserCheck /></p>
        <h2 className="appPageTitle"> | Account Activation Required</h2>

        <Row>
          <Col lg={6} md={8} sm={12}>
            <div className="activation-message">
              <h2>Account Activation Required</h2>
              <p>
                Your account has not been activated yet. Please check your email for an activation link. 
                If you have not received the email, you can resend the activation link.
              </p>
              {message && <Alert variant={variant}>{message}</Alert>} {/* Show alert message */}
              <Button 
                variant="primary" 
                onClick={handleResendActivationEmail} 
                className="appButton"
                disabled={isSending} // Disable button while sending
              >
                {isSending ? 'Sending...' : 'Resend Activation Email'}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ActivationRequiredPage;
