import React, { useState, useContext } from 'react';
import axios from '../api/axios';
import config from '../api/config';

axios.defaults.withCredentials = true;

const PlanContext = React.createContext();

export function usePlan() {
  return useContext(PlanContext);
}

export function PlanContextProvider({ children }) {
  const [plans, setPlans] = useState([]);

  // Fetch history by user ID
  const getPlans = async () => {
    
    try {
      const response = await axios.get(`/plans/`);
      setPlans(response.data);
    } catch (error) {
      console.log(`Error fetching histories: ${error}`);
    }
  };

  const value = {
    plans,
    getPlans,
  };

  return (
    <PlanContext.Provider value={value}>
      {children}
    </PlanContext.Provider>
  );
}

export default PlanContextProvider;
