import React, { useState } from 'react';
import { Button, Form, InputGroup, ToggleButton, ToggleButtonGroup, Modal } from 'react-bootstrap';
import { FaCopy, FaLink, FaCode } from 'react-icons/fa';
import { useFramework } from '../context/FrameworkContext';
import config from '../api/config'; // Import config for base URLs
import RequirePlan from './RequirePlan';

const ShareFrameworkComponent = ({ frameworkId, show, handleClose }) => {
    const { generateShareToken } = useFramework();
    const [shareOption, setShareOption] = useState('link'); // 'link' or 'embed'
    const [generatedContent, setGeneratedContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleToggle = (value) => {
        setShareOption(value); // Set the share option based on the toggle
        setGeneratedContent(''); // Clear generated content when toggling
    };

    const generateShareContent = async () => {
        setIsLoading(true);
        const token = await generateShareToken(frameworkId);
        if (token) {
            const baseUrl = config.clientBasedURL; // Use clientBasedURL from config
            const link = `${baseUrl}/shared/framework/${token}`;
            
            if (shareOption === 'link') {
                setGeneratedContent(link);
            } else {
                const embedCode = `<iframe src="${link}" width="800" height="600" frameBorder="0" allowFullScreen></iframe>`;
                setGeneratedContent(embedCode);
            }
        } else {
            setGeneratedContent('Failed to generate shareable content.');
        }
        setIsLoading(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(generatedContent);
        alert('Copied to clipboard!');
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Share Framework</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <RequirePlan allowedPlans={['premium']} message="Sorry, you need a premium plan to share frameworks click here to upgrade." >
                <ToggleButtonGroup 
                    type="radio" 
                    name="shareOption" 
                    value={shareOption} 
                    onChange={handleToggle} 
                    className="mb-3"
                >
                    <ToggleButton variant="outline-primary" value="link" id="share-link">
                        <FaLink /> Share Link
                    </ToggleButton>
                    <ToggleButton variant="outline-primary" value="embed" id="share-embed">
                        <FaCode /> Embed Code
                    </ToggleButton>
                </ToggleButtonGroup>

                <Button 
                    variant="primary" 
                    onClick={generateShareContent} 
                    disabled={isLoading} 
                    className="mb-3"
                >
                    {isLoading ? 'Generating...' : 'Generate'}
                </Button>

                {generatedContent && (
                    <InputGroup className="mt-3">
                        <Form.Control as="textarea" rows={2} value={generatedContent} readOnly />
                        <Button variant="outline-secondary" onClick={handleCopy}>
                            <FaCopy />
                        </Button>
                    </InputGroup>
                )}
                </RequirePlan>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShareFrameworkComponent;
