import React from 'react'
import ChangePassword from '../components/ChangePassword'
import { Container } from 'react-bootstrap'
import { FaKey } from 'react-icons/fa';
export default function ChangePasswordPage() {
  return (
    <Container>
    
    <p className="appPageIcon"><FaKey /></p>
    <h2 className="appPageTitle"> | Change Password</h2>
    <div><ChangePassword/></div>
    </Container>
  )
}
