import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'

import Header from '../components/Header';
import HelpCategoriesList from '../components/HelpCategoriesList';
import HelpCategoryContextprovider from '../context/HelpCategoriesContext';
import { FaQuestion } from 'react-icons/fa'
export default function HelpPage() {
 
  return (
    <>

    <Container>
    <p className="appPageIcon"><FaQuestion/></p>
    <h2 className="appPageTitle"> | Help</h2>
    <HelpCategoryContextprovider>
      
        <Row>
        <Col>
        <HelpCategoriesList categoryName="Knowlegebase" isVideo={true}/>
        </Col>
      </Row>
     
      
      
      </HelpCategoryContextprovider>
      
    </Container>
    </>
    
  )
}
