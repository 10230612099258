import React from 'react'
import { Container } from 'react-bootstrap'
import { FaBook } from 'react-icons/fa'
export default function NotFoundPage() {
  return (
    <Container>
      <p className="appPageIcon"><FaBook/></p>
      <h2 className="appPageTitle">| Page Not Found</h2>
      <div>
        <p>
          Sorry!
          </p>
        <p>
          The page you are looking for does not exist.
          please check your URL and try again.
        </p>

      </div>
    </Container>
  )
}
