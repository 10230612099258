import React from 'react'
import Function from './Function'
import {Row,Col} from 'react-bootstrap'
import './Role.css';
import {FaUserCog} from 'react-icons/fa'

import { useFramework } from '../context/FrameworkContext';
export default function Role(props) {

    const {framework}=useFramework();
    
    let roleIndex = framework.roles.findIndex((role)=>role._id===props.id)
  return (
    <div className="role">
    <Row>
        <Col xs="12" sm="12" lg="10" md="10" className="roleName"><FaUserCog className="icon"/> {props.roleName}</Col>
    </Row>
    <Row>
        <Col xs="12" sm="12" lg="12" md="12" className="roleDescription">&nbsp;{props.roleDescription}</Col>
    </Row>
    <Row className="roleFunctionsBlock">
        <Col className="">

        {framework.roles[roleIndex].functions.map((theFunction)=>{
            return(

                <Function
                key={theFunction._id}
                functionName={theFunction.name}
                functionDescription={theFunction.description}
                competencies={theFunction.competencies}/>
            

            )


        })}
        
       
        </Col>    
    </Row>
    </div>
  )
}
