import { React, useRef, useState, useEffect } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { FaInfoCircle, FaCheck, FaTimesCircle } from 'react-icons/fa';

import './SignUp.css';
import { Link } from 'react-router-dom';
import Logo from '../assets/logoShape.png';
import { useAuth } from '../context/AuthContext';

export default function SignUp() {
  // Regular expressions for validating fields
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;

  // Messages to show if user info is invalid
  const userInfoMessages = {
    email: 'Please use a valid email address',
    password: 'Please use a password with 8 to 24 characters, must include upper and lower case characters, a special character, and a number',
    matchingPassword: 'Confirm password field does not match the password field',
  };

  const [userInfo, setUserInfo] = useState({ name: '', email: '', password: '', matchingPassword: '' });
  const [userInfoIsValid, setUserInfoIsValid] = useState({ email: false, password: false, matchingPassword: false });
  const [userInfoHasFocus, setUserInfoHasFocus] = useState({ name: false, email: false, password: false, matchingPassword: false });
  const [message, setMessage] = useState({ success: false, message: '' });

  const { registerUser } = useAuth();

  // Reset the message when the user types in any field
  useEffect(() => {
    setMessage({ success: false, message: '' });
  }, [userInfo]);

  // Validate fields only on blur or when the user submits the form
  const handleBlur = (event) => {
    const { name } = event.target;
    validateField(name);
    setUserInfoHasFocus((prevValues) => ({ ...prevValues, [name]: true }));
  };

  // Validate the specific field
  const validateField = (name) => {
    let isEmailValid = EMAIL_REGEX.test(userInfo.email);
    let isPasswordValid = PASSWORD_REGEX.test(userInfo.password);
    let isMatchingPassword = userInfo.matchingPassword === userInfo.password;

    // Update the validation state based on the field
    setUserInfoIsValid((prevValues) => ({
      ...prevValues,
      email: name === 'email' ? isEmailValid : prevValues.email,
      password: name === 'password' ? isPasswordValid : prevValues.password,
      matchingPassword: name === 'matchingPassword' ? isMatchingPassword : prevValues.matchingPassword,
    }));

    // Check the email field
    if (name === 'email' && !isEmailValid && userInfoHasFocus.email) {
      setMessage({ success: false, message: 'Problem with email' });
    }
  };

  // Validate all fields when the form is submitted
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isEmailValid = EMAIL_REGEX.test(userInfo.email);
    let isPasswordValid = PASSWORD_REGEX.test(userInfo.password);
    let isMatchingPassword = userInfo.matchingPassword === userInfo.password;

    if (!isEmailValid || !isPasswordValid || !isMatchingPassword) {
      setUserInfoIsValid({
        email: isEmailValid,
        password: isPasswordValid,
        matchingPassword: isMatchingPassword,
      });
      setMessage({
        success: false,
        message: 'Please correct the highlighted fields',
      });
      return;
    }

    let result = await registerUser({
      fullName: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
    });
    setMessage(result);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <>
      <Row className="justify-content-center">
        <Col sm={8} xs={10} lg={4} md={5} className="authenticationContainer">
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ margin: '20px' }}>
              <img src={Logo} alt="Edu Framework logo" className="logoShape" /> Sign Up
            </h3>
          </div>
          {message.message?.length > 0 ? (
            <Alert className={message.success === true ? 'alert-success' : 'alert-danger'}>
              <span>
                <FaInfoCircle /> {message.message}
              </span>
            </Alert>
          ) : null}
          {message.success !== true ? (
            <Form className="mp-3 authenticationForm">
              <Form.Group className="mb-2">
                <Form.Label>&nbsp;Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-2">
                {userInfoHasFocus.email ? (userInfoIsValid.email ? <FaCheck /> : <FaTimesCircle />) : null}
                <Form.Label>&nbsp;Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                ></Form.Control>
              </Form.Group>
              {userInfoHasFocus.email && !userInfoIsValid.email ? <p className="fieldMessage">{userInfoMessages.email}</p> : null}
              <Form.Group className="mb-2">
                {userInfoHasFocus.password ? (userInfoIsValid.password ? <FaCheck /> : <FaTimesCircle />) : null}
                <Form.Label>&nbsp;Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                ></Form.Control>
              </Form.Group>
              {userInfoHasFocus.password && !userInfoIsValid.password ? <p className="fieldMessage">{userInfoMessages.password}</p> : null}
              <Form.Group className="mb-2">
                {userInfoHasFocus.matchingPassword ? (userInfoIsValid.matchingPassword ? <FaCheck /> : <FaTimesCircle />) : null}
                <Form.Label>&nbsp;Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="matchingPassword"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                ></Form.Control>
              </Form.Group>
              {userInfoHasFocus.matchingPassword && !userInfoIsValid.matchingPassword ? (
                <p className="fieldMessage">{userInfoMessages.matchingPassword}</p>
              ) : null}
              <div className="d-grid gap-2 mt-5">
                <Button className="appButton" variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </Form>
          ) : null}
        </Col>
      </Row>
      <p></p>
      <div style={{ textAlign: 'center', fontSize: 'small' }}>
        <p>
          Already have an account <Link to="/signin">Sign In</Link>
        </p>
      </div>
    </>
  );
}
