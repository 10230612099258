import React, { useEffect, useState } from 'react';
import CollapsableItem from './CollapsableItem';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { useHelpCategory } from '../context/HelpCategoriesContext';
import './HelpCategoriesList.css';
import learningResourceBanner from '../assets/animation.gif';

export default function HelpCategoriesList(props) {
  const { getHelpCategories, userHelpCategories } = useHelpCategory();
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Fetch help categories and set loading state
    const fetchCategories = async () => {
      setLoading(true);
      await getHelpCategories(props.categoryName);
      setLoading(false); // Hide loader when done
    };

    fetchCategories();
  }, [props.categoryName]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Row className="helpCategoryTitle justify-content-md-center">
        <Col lg={10}>
          <Form.Control
            type="text"
            name="search"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search help topics..."
            className="mb-4"
          />
        </Col>
      </Row>

      {/* Display loader or content based on loading state */}
      {loading ? (
        <Row className="justify-content-md-center">
          <Col lg={6} sm={12} xs={12} md={6} className="text-center">
            <img src={learningResourceBanner} alt="Loading animation" width="300px" />
          </Col>
        </Row>
      ) : (
        userHelpCategories?.map((category, index) => (
          <React.Fragment key={index}>
            {props.isVideo?
            <Row>
            {category.subjects
                  ?.filter((subject) =>
                    subject?.title?.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((subject, idx) => (
                    <Col lg={3} md={6} sm={12} xs={12} >
                    <div className="videoContainer">
                      <p><b>{subject?.title}</b></p>
                      <iframe width="100%" height="250px" src={subject?.videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    </Col>
                  ))}
            </Row>
            :<Row>
              <Col>
                {category.subjects
                  ?.filter((subject) =>
                    subject?.title?.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((subject, idx) => (
                    <CollapsableItem key={idx} subject={subject} />
                  ))}
              </Col>
            </Row>}
          </React.Fragment>
        ))
      )}
    </>
  );
}
