import React from 'react'
import {useCourse} from '../context/CourseContext';
import {Modal,Button} from 'react-bootstrap'
import Message from './Message'
import CourseEditor from './CourseEditor';

export default function CourseEditorModal() {
const {setEditModalIsVisible,selectedCourse,editModalIsVisible,setMessage,message,selectedCourseId,actionType,updateCourse,saveCourse}=useCourse();
  const hideMessageAndContinueEditing=()=>{
    setMessage({target:"courseModal",header:"",text:"",variant:"",buttons:[],isVisible:false})
  }
  const hideMessageAndHideModal=()=>{
    setMessage({target:"courseModal",header:"",text:"",variant:"",buttons:[],isVisible:false})
    setEditModalIsVisible(false);
    
  }

  const handleSaveCourse=()=>{
    console.log(selectedCourseId)

    if (actionType==="edit"){
 
      updateCourse(selectedCourseId)
    }
    if (actionType==="add"){
      saveCourse(selectedCourseId)
    }
    
    setEditModalIsVisible(false);
    
  }
  
  const handleClose=()=>{
    setMessage({target:"courseModal",header:"Are You Sure",
                text:"You are canceling without saving, note that all unsaved information will be be lost ",
                variant:"warning",
                buttons:[{text:"Yes",action:hideMessageAndHideModal},
                         {text:"No",action:hideMessageAndContinueEditing}],
                isVisible:true})
      
  }



    return (
        <>
        <Modal show={editModalIsVisible} onHide={handleClose} size='lg' centered fullscreen>
          <Modal.Header closeButton>
            <Modal.Title>Edit Course</Modal.Title>
          </Modal.Header>
    
          <Modal.Body>
            {message.target==="courseModal"?
            <Message
             
              header={message.header}
              text={message.text}
              buttons={message.buttons}
              show={message.isVisible}
              variant={message.variant}
              position="fixed"
            ></Message>:null}
            <CourseEditor/>
          </Modal.Body>
    
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" onClick={handleSaveCourse}>Save changes</Button>
          </Modal.Footer>
        </Modal>
        </>
      )
}
