import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import PayPalPaymentComponent from './PayPalPaymentComponent'; // PayPal component
import axios from '../api/axios'; // Adjust the axios path as needed
import { useAuth } from '../context/AuthContext'; // Authentication context
import config from '../api/config';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from '../context/HistoryContext';
import  { usePlan } from '../context/PlanContext';
import { formatDate } from '../utilities/DateTools';
import "./PlanPurchase.css"

const PlanPurchase = () => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const navigate = useNavigate();
  const { user, updateCurrentPlan } = useAuth();

  const { plans, getPlans } = usePlan();
  useEffect(()=>{
    getPlans();
  },[])
 const handlePlanSelection = (e) => {
   const plan = plans.find((p) => p._id === e.target.value);
   if (plan) {
    setSelectedPlan(plan);
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
  }} 
   
  
   
  ;
  

  // Fetch plan details from localStorage
  useEffect(() => {
    const plan = JSON.parse(localStorage.getItem('selectedPlan'))||{"name":"Personal","type":"Regular","cost":"19.99","duration":"365","features":[{"isAvailable":true,"feature":"Creating multiple frameworks"},{"isAvailable":true,"feature":"Manage Programs"},{"isAvailable":true,"feature":"Manage Courses Information"},{"isAvailable":false,"feature":"Use AI Capabilities"},{"isAvailable":true,"feature":"Manage Certificates"},{"isAvailable":true,"feature":"Generate Reports"},{"isAvailable":false,"feature":"Publishing Frameworks"}],"description":"The Personal Plan, great for indviduals who want to create their own frameworks:","icon":"biking"};
   
    setSelectedPlan(plan);
  }, []);

  // Handle successful payment
  const handlePaymentSuccess = async (details) => {
    try {
      // Mark payment as successful
      setPaymentSuccessful(true);
      const { payer } = details;
      const paymentMethod = "PayPal";

      // Update the user's current plan
      const response = await axios.post(`${config.apiBaseURL}/update-plan`, {
        userId: user._id,
        planId: selectedPlan._id,
        cost: selectedPlan.cost,
        paymentMethod,
      });

      if (response.data.success) {
        // Update the current plan in AuthContext
        updateCurrentPlan(selectedPlan);

        // Redirect to dashboard or a success page
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error updating user plan:', error);
    }
  };

  const isPlanExpired = user.subscriptionEndDate && new Date(user.subscriptionEndDate) < new Date();
  
  return (
    <div className="plan-purchase-container">
      <Row className="justify-content-md-center" style={{ padding: '50px' }}>
       
         
            <>
            <Col lg={7} md={6}>
            <h2>You are currently on </h2>
            <p> <strong>{user.currentPlan} Plan</strong>, the plan expires on {formatDate(user.subscriptionEndDate)}</p>{isPlanExpired?<span className="blueBadge">Expired</span>:<span className="blueBadge">Valid</span>}
              {selectedPlan?<>
              <h2>Selected Plan to Purchase</h2>
              <p>You are purchasing the <strong>{selectedPlan.name}</strong> plan.</p></>:<span>No plan selected please select one of the plans to upgrade</span>}
              <Form.Control as="select" onChange={handlePlanSelection} value={selectedPlan._id}>
                <option> </option>
                {plans?.map((plan, index) => {
                  return (
                    plan.cost>0?<option key={index} value={plan._id}>{plan.name}</option>:null
                  )
                })
                }
              </Form.Control>
              <p></p>
              <p><strong>Monthly Price:</strong> ${selectedPlan.cost} CAD </p>
              <p><strong>Pay Yearly:</strong> the total of  <strong> ${(selectedPlan.cost*12).toFixed(2)}</strong> CAD</p>
              <p><strong>Duration: </strong>{selectedPlan.duration} days</p>
              <p><strong>Features: </strong>
              <ul>
                {selectedPlan?.features?.map((item, index) => (
                  item.isAvailable? <li key={index}><FaCheckCircle /> {item.feature}</li>:null
                ))}
              </ul>{}</p>
              
            </Col>
            <Col>
            <h2>Payment</h2>
            <p>To proceed with payment please click on the button below</p>
            <p>You are paying the total of <strong>${(selectedPlan.cost*12).toFixed(2)}.</strong> CAD/year</p>
            
              <PayPalPaymentComponent
                userId={user._id}
                purchasedPlan={selectedPlan}
                onSuccess={handlePaymentSuccess} // Handle payment success
              />

              {paymentSuccessful && (
                <div className="alert alert-success mt-3">
                  Payment successful! Updating your plan...
                </div>  
              )}
            </Col>
            </>
      
          
          </Row>
        
    </div>
  );
};

export default PlanPurchase;
