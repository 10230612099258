import React, { useEffect } from 'react';
import { useCertificate } from '../context/CertificateContext'; // Assume context for certificates exists
import { Table } from 'react-bootstrap'; // Use Bootstrap Table for formatting

const AllCertificatesReport = () => {
  const { userCertificates, getUserCertificates } = useCertificate();

  // Fetch all certificates when the component is mounted
  useEffect(() => {
    getUserCertificates(); // Function to fetch all certificates
  }, [getUserCertificates]);

  return (
    <div className="all-certificates-report">
      <h2>All Certificates Report</h2>
      {userCertificates?.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Certificate Name</th>
              <th>Level</th>
            </tr>
          </thead>
          <tbody>
            {userCertificates.map((certificate, index) => (
              <tr key={certificate._id}>
                <td>{index + 1}</td>
                <td>{certificate.name}</td>
                <td>{certificate.level}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No certificates available.</p>
      )}
    </div>
  );
};

export default AllCertificatesReport;
