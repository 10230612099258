import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Custom styling for the banner

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCookies, setSelectedCookies] = useState({
    essential: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(selectedCookies));
    setIsVisible(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedCookies((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    isVisible && (
      <div className="cookie-consent-banner">
        <h4>We use cookies</h4>
        <p>Please select which types of cookies you accept to use our website:</p>
        <div className="cookie-options">
          <div>
            <input
              type="checkbox"
              name="essential"
              checked={selectedCookies.essential}
              disabled
            />
            <label>Essential Cookies (required)</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="analytics"
              checked={selectedCookies.analytics}
              onChange={handleCheckboxChange}
            />
            <label>Analytics Cookies</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="marketing"
              checked={selectedCookies.marketing}
              onChange={handleCheckboxChange}
            />
            <label>Marketing Cookies</label>
          </div>
        </div>
        <button onClick={handleConsent} className="accept-button">
          Accept Selected
        </button>
      </div>
    )
  );
}
