import React,{useState,useContext} from 'react'
import axios from '../api/axios';
import config from '../api/config'
axios.defaults.withCredentials = true
const ProgramContext=React.createContext();

export function useProgram(){
    return useContext(ProgramContext)
}

export function ProgramContextprovider({children}){
const [userPrograms,setUserPrograms]=useState([]);
const [selectedProgramId,setSelectedProgramId]=useState("");
const[message,setMessage]=useState({header:"",text:"",variant:"",buttons:[],isVisible:false})
const[selectedProgram,setSelectedProgram]=useState({})
const [actionType,setActionType]=useState("")
const [editModalIsVisible,setEditModalIsVisible]=useState(false)
const getUserPrograms=async()=>{
   
        await axios.get('/programs/').then(res=>{
            
            setUserPrograms(res.data)
            
        } )
        .catch(error=> console.log(`Error: $error`));
    }

    const saveProgram=()=>{

        let emptyProgram={name:"program name",description:"program description",audience:{who:"",backgroundKnowledge:"",ageFrom:18,ageTo:60,preferedDeliveryMethod:"Online",stakeholders:[],level:"",courses:[], relatedFrameworks:[]}}
        
       
        axios.post('/programs/', emptyProgram).then(response=>{ 
            
            getUserPrograms()
            setSelectedProgramId(response.data._id)
        
        })
       
        
       
    }
    const updateProgram=async(programID)=>{
        console.log(selectedProgram)
   
        
        await axios.put('/programs/'+programID, {
        ...selectedProgram
        })
        .then(response => {
            console.log(response);
            getUserPrograms();
        })
        .catch(error => {
            console.log(error);
        });
    }
    const removeProgram=async (programID)=>{
        console.log(programID)
      
        await axios.delete('/programs/'+ programID)
        await getUserPrograms()
      
    }

    
    const value={
        userPrograms,
        selectedProgramId,
        selectedProgram,
        message,
        getUserPrograms,
        removeProgram,
        setEditModalIsVisible,
        editModalIsVisible,
        setSelectedProgramId,
        setSelectedProgram,
        setMessage,
        updateProgram,
        saveProgram,
        actionType,
        setActionType
    }

    return (
        <ProgramContext.Provider value={value}>
            {children}
        </ProgramContext.Provider>
    )
}

export default ProgramContextprovider;