import React, { useState } from 'react';
import axios from '../api/axios';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
import './ForgotPassword.css';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/auth/forgot-password', { email });
      setMessage(response.data.message);
      setVariant('success');
    } catch (error) {
      console.error('Error details:', error); // Log error details
    if (error.response) {
      setMessage(error.response.data.message || 'Error sending email');
    } else if (error.request) {
      setMessage('No response received from the server');
    } else {
      setMessage('Error setting up request');
    }
    setVariant('danger');
  }
  };

  return (
    <Row >
      
      <Col lg={6} md={8} sm={12}>
      <p> </p>
      <p>
        Please enter your email address. We will send you a reset link if you have an account with us.
      </p>
    <Form onSubmit={handleSubmit} className="forgotPasswordForm">
      <Form.Group controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="mt-3 appButton">
        Send Reset Link
      </Button>
      {message && <Alert variant={variant} className="mt-3">{message}</Alert>}
    </Form>
    </Col>
    </Row>
  );
};

export default ForgotPassword;