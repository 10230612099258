import React, { useEffect } from "react";
import ProgramView from "./ProgramView";
import GenericList from "./GenericList";
import { useProgram } from "../context/ProgramContext";
import { Row, Col } from "react-bootstrap";
import "./ProgramsList.css";
import SideMenuToggle from "./SideMenuToggle";

export default function ProgramsList(props) {
  const {
    getUserPrograms,
    setActionType,
    setEditModalIsVisible,
    removeProgram,
    saveProgram,
    userPrograms,
    selectedProgramId,
    setSelectedProgramId,
    message,
    setMessage,
    setSelectedProgram,
  } = useProgram();

  useEffect(() => {
    const initializePrograms = async () => {
      await getUserPrograms();
      if (props.initialProgramId) {
        selectProgramById(props.initialProgramId);
      } else if (userPrograms?.length > 0) {
        selectProgramById(userPrograms[0]._id); // Default to the first program
      }
    };
    initializePrograms();
  }, [props.initialProgramId]);

  const selectProgramById = (programId) => {
    setSelectedProgramId(programId);
    const currentProgram = userPrograms.find((program) => program._id === programId);
    if (currentProgram) {
      setSelectedProgram(currentProgram);
    }
  };

  const handleSelect = (event) => {
    const selectedId = event.target.getAttribute("selectid");
    if (selectedId) {
      selectProgramById(selectedId);
    }
  };

  const handleDelete = (event) => {
    const deleteId = event.target.getAttribute("deleteid");
    if (deleteId) {
      selectProgramById(deleteId);
      setMessage({
        target: "Programs",
        header: "Are you sure?",
        text: "Are you sure you want to delete this program and all its related information?",
        variant: "warning",
        buttons: [
          { text: "Yes, I am sure", action: () => handleCompleteDelete(deleteId) },
          { text: "Cancel", action: handleCancel },
        ],
        isVisible: true,
      });
    }
  };

  const handleCompleteDelete = async (programId) => {
    await removeProgram(programId);
    setMessage({ target: "Programs", header: "", text: "", variant: "", buttons: [], isVisible: false });

    const remainingPrograms = userPrograms.filter((program) => program._id !== programId);
    if (remainingPrograms.length > 0) {
      selectProgramById(remainingPrograms[0]._id);
    } else {
      setSelectedProgramId(null);
      setSelectedProgram(null);
    }
  };

  const handleCancel = () => {
    setMessage({ target: "Programs", header: "", text: "", variant: "", buttons: [], isVisible: false });
  };

  const handleEdit = (event) => {
    const editId = event.target.getAttribute("editid");
    if (editId) {
      selectProgramById(editId);
      setActionType("edit");
      setEditModalIsVisible(true);
    }
  };

  const handleAdd = async () => {
    const newProgramId = await saveProgram();
    if (newProgramId) {
      selectProgramById(newProgramId);
    }
  };

  return (
    <div className="programListContainer">
      <Row>
        <SideMenuToggle>
        <Col lg="4" md="4" sm="6" sx="12">
          <GenericList
            listHeader="Programs"
            columnsHeaders={[""]}
            columnsNames={["name"]}
            data={userPrograms}
            handleSelect={handleSelect}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleAdd={handleAdd}
            selectedItemId={selectedProgramId}
            listMessage={message}
          />
        </Col>
        </SideMenuToggle>
        <Col >
          <ProgramView
            data={userPrograms.find((program) => program._id === selectedProgramId) || null}
          />
        </Col>
      </Row>
    </div>
  );
}
