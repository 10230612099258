import React, { useEffect, useState } from 'react';
import { useFramework } from '../context/FrameworkContext'; // Framework Context for fetching data
import { Form, Table } from 'react-bootstrap'; // Bootstrap for UI components

const SingleFrameworkReport = () => {
  const { userFrameworks, getFrameworks } = useFramework(); // Access framework data and fetching function
  const [selectedFrameworkId, setSelectedFrameworkId] = useState('');
  const [selectedFramework, setSelectedFramework] = useState(null);

  // Fetch all frameworks on component mount
  useEffect(() => {
    getFrameworks();
  }, [getFrameworks]);

  // Handle framework selection
  const handleFrameworkSelection = (e) => {
    const frameworkId = e.target.value;
    setSelectedFrameworkId(frameworkId);

    if (frameworkId) {
      // Find the selected framework from the list
      const framework = userFrameworks.find((fw) => fw._id === frameworkId);
      setSelectedFramework(framework);
    } else {
      setSelectedFramework(null);
    }
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date) ? date.toLocaleString() : 'N/A';
  };

  return (
    <div className="single-framework-report">
      <h2>Framework Details Report</h2>
      
      {/* Dropdown for selecting a framework */}
      <Form.Group controlId="frameworkSelect">
        <Form.Label>Select Framework</Form.Label>
        <Form.Control 
          as="select" 
          value={selectedFrameworkId} 
          onChange={handleFrameworkSelection}>
          <option value="">-- Select a Framework --</option>
          {userFrameworks.map((framework) => (
            <option key={framework._id} value={framework._id}>
              {framework.frameworkName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Display the selected framework's details */}
      {selectedFramework ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Framework Name</td>
                <td>{selectedFramework.frameworkName}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{selectedFramework.frameworkDescription}</td>
              </tr>
              <tr>
                <td>Created At</td>
                <td>{formatDate(selectedFramework.createdAt)}</td>
              </tr>
              {/* Add more framework properties as needed */}
            </tbody>
          </Table>

          {/* Display roles, functions, and competencies */}
          {selectedFramework.roles && selectedFramework.roles.length > 0 ? (
            <div>
              <h3>Roles</h3>
              {selectedFramework.roles.map((role) => (
                <div key={role._id}>
                  <h4>Role: {role.name}</h4>
                  <p>Description: {role.description}</p>

                  {/* Functions within each role */}
                  {role.functions && role.functions.length > 0 && (
                    <div>
                      <h5>Functions</h5>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Function Name</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {role.functions.map((func) => (
                            <tr key={func._id}>
                              <td>{func.name}</td>
                              <td>{func.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {/* Competencies within each function */}
                      {role.functions.map((func) => (
                        <div key={func._id}>
                          <h6>Competencies for Function: {func.name}</h6>
                          {func.competencies && func.competencies.length > 0 ? (
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Competency Name</th>
                                  <th>Description</th>
                                  <th>Level</th>
                                  <th>Status</th>
                                  <th>Type</th>
                                  <th>Requirement of Certificates</th>
                                </tr>
                              </thead>
                              <tbody>
                                {func.competencies.map((competency) => (
                                  <tr key={competency._id}>
                                    <td>{competency.name}</td>
                                    <td>{competency.description}</td>
                                    <td>{competency.level}</td>
                                    <td>{competency.status}</td>
                                    <td>{competency.type}</td>
                                    <td>
                                      {competency.requirementOf.length > 0 ? (
                                        <ul>
                                          {competency.requirementOf.map((cert) => (
                                            <li key={cert.name}>
                                              {cert.name} ({cert.level})
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        'None'
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <p>No competencies available for this function.</p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>No roles available in this framework.</p>
          )}
        </>
      ) : (
        <p>Please select a framework to see its details.</p>
      )}
    </div>
  );
};

export default SingleFrameworkReport;
