import React from 'react'
import HistoryContextProvider from '../context/HistoryContext'
import PurchaseHistory from '../components/PurchaseHistory'

import { FaHistory} from 'react-icons/fa'
import { Container } from 'react-bootstrap'

export default function CertificationPage() {
  
  return (
   <>

   
   <HistoryContextProvider>

       <Container className="appPageContainer">
        <p className="appPageIcon"><FaHistory/></p>
        
        <h2 className="appPageTitle"> | Purchase History</h2>
        <PurchaseHistory/>
    </Container>
  </HistoryContextProvider>




   </>
  )
}
