import React, { useState, useContext } from 'react';
import axios from '../api/axios';
import config from '../api/config';

axios.defaults.withCredentials = true;

const HistoryContext = React.createContext();

export function useHistory() {
  return useContext(HistoryContext);
}

export function HistoryContextProvider({ children }) {
  const [userHistories, setUserHistories] = useState([]);

  // Fetch history by user ID
  const getUserHistories = async () => {
   
    try {
      const response = await axios.get(`/histories/`);
      setUserHistories(response.data);
    } catch (error) {
      console.log(`Error fetching histories: ${error}`);
    }
  };

  const value = {
    userHistories,
    getUserHistories,
  };

  return (
    <HistoryContext.Provider value={value}>
      {children}
    </HistoryContext.Provider>
  );
}

export default HistoryContextProvider;
