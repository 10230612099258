import React from 'react'
import { useParams } from 'react-router-dom';
import AccountActivator from '../components/AccountActivator';  // Make sure the import path is correct
const AccountActivationPage: React.FC = () => {
    const { token } = useParams();
    
  return (
    <>
        
    <div className="container mt-5">
        <h1>Activate Your Account</h1>
        {token && <AccountActivator token={token} />}
    </div>
   
    </>
  )
}
export default AccountActivationPage;

