// FrameWork.js
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FaPrint, FaShareAlt, FaInfoCircle } from 'react-icons/fa';
import './Framework.css';
import Role from './Role';
import ColorCodeSelector from './ColorCodeSelector';
import CompetencyLegend from './CompetencyLegend';
import { useFramework } from '../context/FrameworkContext';
import { useReactToPrint } from 'react-to-print';
import ShareFrameworkComponent from './ShareFrameworkComponent';
import RequirePlan from './RequirePlan';

const MemoizedRole = React.memo(Role);

function FrameWork() {
    const  { framework, coloredBy, competencySettings,userFrameworks,selectedFrameworkID,setFramework } = useFramework();
    const frameworkRef = useRef();
    const [showLegend, setShowLegend] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    const handlePrint = useCallback(
        useReactToPrint({
            content: () => frameworkRef.current,
            documentTitle: framework?.frameworkName || 'Framework Report',
        }),
        [framework]
    );

    const handleOpenShareModal = () => setShowShareModal(true);
    const handleCloseShareModal = () => setShowShareModal(false);
   
    return (
        framework?.frameworkName?.length>0?
        <>
            <div ref={frameworkRef} style={{ padding: '20px' }}>
                <Row>
                    <Col>
                        <Button
                            title="Print"
                            onClick={handlePrint}
                            variant="primary"
                            className="shareButton"
                            
                        >
                            <FaPrint />
                        </Button>
                        
                        <Button
                            title="Share"
                            onClick={handleOpenShareModal}
                            variant="primary"
                            className="shareButton"
                           
                        >
                            <FaShareAlt />
                        </Button>
                       
                    </Col>
                </Row>
                <Row className="frameworkInfoBlock">
                    <Col>
                        <div className="frameworkInfo">
                            <div className="frameworkName">{framework?.frameworkName}</div>
                            <div className="frameworkDescription">{framework?.frameworkDescription}</div>
                        </div>
                    </Col>
                    
                   
                </Row>
                <Row>
                <Col>
                
                        <Button
                            title="Toggle Legend"
                            onClick={() => setShowLegend(!showLegend)}
                            variant=""
                            className="legendToggleButton"
                            style={{ marginTop: '15px',fontSize:'small' }}
                        >
                           Toogle show legend <FaInfoCircle />
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6">
                        <ColorCodeSelector />
                    </Col>
                </Row>

                {showLegend && (
                    <Row className="legendContainer">
                        <Col xs="12" sm="12" md="12" lg="12">
                            <CompetencyLegend coloredBy={coloredBy} legendData={competencySettings} />
                        </Col>
                    </Row>
                )}

                <Row style={{ width: '100%' }}>
                    {framework?.roles?.map((role, index) => (
                        <Col key={index} xs="12" sm="12" md="6" lg="6">
                            <MemoizedRole
                                key={role._id}
                                id={role._id}
                                roleName={role.name}
                                roleDescription={role.description}
                            />
                        </Col>
                    ))}
                </Row>
            </div>

            <ShareFrameworkComponent
                frameworkId={framework?._id}
                show={showShareModal}
                handleClose={handleCloseShareModal}
            />
        </>:<><h1 className="programInformation">Framework Information</h1><p>No framework selected</p></>
    );
}

export default React.memo(FrameWork);
