import React from 'react';
import ReportsView from '../components/ReportsView';
import SettingContextprovider from '../context/SettingContext'
import { FaWpforms } from 'react-icons/fa';
import { Container} from 'react-bootstrap'
export default function ReportsPage() {
   
    
   
  return (
    <SettingContextprovider>
        <Container>
            <p className="appPageIcon"><FaWpforms /></p>
            <h2 className="appPageTitle">| Reports</h2>
        <ReportsView/>
        </Container>
    </SettingContextprovider>
  
  )
}
