import React, { useEffect,useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useSettings } from '../context/SettingContext'
import { FaMagic } from 'react-icons/fa'
import './ActivityEditor.css';
import config from '../api/config';
import RequirePlan from './RequirePlan';
export default function ActivityEditor(props) {
    const { settingData, getSettingByName } = useSettings();
    const[activity,setActivity]=useState(props.activity)
    const [activityTypes,setActivityTypes]=useState([])
   

   
    const handleAIGenerateContent = (e) => {
        e.preventDefault();
    
        let activityDescription = `The activity name is ${activity.name} with the type ${activity.type} covering in ROPE model the phase of ${activity.phase} described as ${activity.description}`;
        let learningObjective = props.loText;
    
        // Initialize EventSource for streaming data
        const eventSource = new EventSource(`${config.apiBaseURL}/ai-services?learningObjective=${encodeURIComponent(learningObjective)}&activityDescription=${encodeURIComponent(activityDescription)}`);
    
        // Accumulate the incoming data chunks
        let accumulatedData = '';
    
        // Event listener for new content chunks
// Event listener for new content chunks
eventSource.onmessage = (event) => {
    // Parse the incoming chunk of data (assuming JSON format)
    let parsedData;
    try {
        parsedData = JSON.parse(event.data);
    } catch (error) {
        console.error('Failed to parse event data:', error);
        return;
    }

    // Accumulate the "data" field into the content if it is not undefined
    if (parsedData.data && parsedData.data !== 'undefined') {
        accumulatedData += parsedData.data;
    }
    console.log('Accumulated Data:', accumulatedData); // Logs the data progressively

    // Update the content field progressively as data arrives
    setActivity(prevActivity => {
        const updatedContent = (prevActivity.content || '') + (parsedData.data || ''); // Ensure no undefined value is appended
        const updatedData = { ...prevActivity, content: updatedContent };

        // Call the parent function to save the data
        props.onSave(updatedData, props.index);

        // Return updated activity to update the UI
        return updatedData;
    });
};

    
        // Handle errors in the EventSource connection
        eventSource.onerror = (err) => {
            console.error('EventSource failed with error:', err);
            eventSource.close(); // Close the connection on error
        };
    };
    
    
    
    
    
    
    const handleActivityChange=(event)=>{
        let name=event.target.name;
        const updatedData={...activity,[name]:event.target.value}
        setActivity(updatedData)
        props.onSave(updatedData,props.index)
    }
    
   useEffect(()=>{
    if (!settingData?.domains) {
        getSettingByName("domains");
    }
    if(!settingData?.phases){
        getSettingByName("phases");
    }
   

    const getActivityTypes= (domainName, levelName) =>{
        const domain =  settingData?.domains.find(domain => domain.name === domainName);
        if (!domain) {
          return {}
        }
        
        const level =  domain?.levels?.find(level => level.name === levelName);
        if (!level) {
          return {}
        }
        
        return level.activityTypes;
      }
      let activityTypes = getActivityTypes(props.domain,props.level)
      if (Array.isArray(activityTypes)) {setActivityTypes(activityTypes)}
      else {
        setActivityTypes([])
      }
   },[settingData,props])
  return (
    <div className="activityEditor">
    
    <Row>
         <Col>
       
            
            <Row key={props.index}>
            <Col  lg={12} md={12} sm={12} xs={12} >
                    <strong>Activity Name</strong>
                    <Form.Control

                        name="name" 
                        type="text"
                        onChange={handleActivityChange}
                        value={activity.name|| ''}>
                    
                    </Form.Control>
                </Col>
                <Col  lg={8} md={8} sm={12} xs={12} >
                    <strong>Activity Type</strong>
                    <Form.Control name="type" as="select" 
                    onChange={handleActivityChange}
                    value={activity.type || ''}>
                    {activityTypes?.map((activityType,index)=>(
                        <option key={index}>{activityType}</option>
                    ))}
                    </Form.Control>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                    <strong>Activity Phase</strong>
                    <Form.Control 
                    name="phase" as="select" 
                    onChange={handleActivityChange}
                    value={activity.phase || ''}>
                    {settingData?.phases?.map((phase,index)=>(
                        <option value={phase}>{phase}</option>
                    ))}
                    </Form.Control>
                </Col>
                <Col  lg={12} md={12} sm={12} xs={12} >
                    <strong>Activity Description</strong>
                    <Form.Control

                        name="description" 
                        type="text"
                        onChange={handleActivityChange}
                        value={activity.description || ''}>
                    
                    </Form.Control>

                    
                </Col>
                </Row>
                <Row>
                <Col  lg={12} md={12} sm={12} xs={12} >
                    <strong>Activity Content</strong> <RequirePlan allowedPlans={['Basic','Standard','Premium']} message=" To activate AI upgrade to standard or premium plan "><button onClick={handleAIGenerateContent} className='aiButton'><FaMagic/> AI Content</button></RequirePlan>
                    <Form.Control

                        name="content" 
                        as="textarea"
                        rows={5}
                        onChange={handleActivityChange}
                        value={activity.content || ''}>
                    
                    </Form.Control>
                </Col>
            </Row>
       
        </Col>
    </Row>
    </div>
  )
}
