import React, { useContext, useState, useEffect } from "react";
import axios from '../api/axios';
import { useAuth } from "./AuthContext";
import config from "../api/config";
import { v4 as uuid } from 'uuid'; 

axios.defaults.withCredentials = true;
const FrameworkContext = React.createContext();

export function useFramework() {
    return useContext(FrameworkContext);
}

export function FrameworkContextprovider({ children }) {

    const [userFrameworks, setUserFrameworks] = useState([]);
    const [selectedFrameworkID, setSelectedFrameworkID] = useState();
    const [framework, setFramework] = useState({ frameworkName: "", frameworkDescription: "", roles: [] });
    const [selectedCompetency, setSelectedCompetency] = useState({});
    const [tooltipPosition, setTooltipPosition] = useState({});
    const [tooltipIsVisible, setTooltipIsVisible] = useState('hidden');
    const [coloredBy, setColoredBy] = useState('type');
    const [editModalIsVisible, setEditModalIsVisible] = useState(false);
    const [message, setMessage] = useState({ header: "", text: "", variant: "", buttons: [{ text: "" }], isVisible: false });

    const competencySettings = {
        type: [
            { label: 'Core Competencies', color: '#6DAEDB' }, // Soft Blue
            { label: 'Technical or Functional Competencies', color: '#F0C674' }, // Soft Yellow
            { label: 'Leadership Competencies', color: '#A8B9CC' }, // Light Gray-Blue
            { label: 'Management Competencies', color: '#C0A8B9' }, // Soft Lavender
            { label: 'Interpersonal Competencies', color: '#FFD966' }, // Soft Light Yellow
            { label: 'Analytical Competencies', color: '#9DBAD5' }, // Pale Blue
            { label: 'Digital Competencies', color: '#FFB566' }, // Pastel Orange
            { label: 'Business Competencies', color: '#F5D19B' }, // Soft Beige
            { label: 'Personal Effectiveness Competencies', color: '#ADC9D9' }, // Light Pastel Blue
            { label: 'Ethical and Legal Competencies', color: '#D9B9C1' }, // Light Pastel Pink
            { label: 'Safety Competencies', color: '#B4C7D9' }, // Light Gray-Blue
            { label: 'Environmental Competencies', color: '#E8C399' }, // Soft Tan
            { label: 'Communication and Language Competencies', color: '#A4C4E0' }, // Light Blue
            { label: 'Customer Service Competencies', color: '#FFD1B2' }, // Soft Peach
            { label: 'Sales and Marketing Competencies', color: '#D1D9E0' }, // Light Gray
            { label: 'Innovation Competencies', color: '#E7A4B2' }, // Soft Rose
            { label: 'Learning and Development Competencies', color: '#B4D3E7' }, // Light Aqua
            { label: 'Organizational Competencies', color: '#E7C4A4' }, // Soft Terracotta
            { label: 'Research Competencies', color: '#F2D1A0' }, // Soft Light Orange
            { label: 'Supply Chain and Logistics Competencies', color: '#AFC7C0' }, // Soft Teal
            { label: 'Human Resources Competencies', color: '#C0A9B9' }, // Soft Plum
            { label: 'Quality Management Competencies', color: '#D9E0A4' }, // Light Lime
            { label: 'Administrative Competencies', color: '#D9C0B9' }, // Light Tan
            { label: 'Legal Competencies', color: '#E0D9B9' }, // Soft Pastel Yellow
        ],
        level: [
            { label: 'Novice', color: '#A4C4E0' }, // Light Blue
            { label: 'Intermediate', color: '#FFD966' }, // Soft Light Yellow
            { label: 'Proficient', color: '#FFB566' }, // Pastel Orange
            { label: 'Advanced', color: '#9DBAD5' }, // Pale Blue
            { label: 'Expert', color: '#6DAEDB' }, // Soft Blue
        ],
        required: [
            { label: 'Must have', color: '#FFD1B2' }, // Soft Peach
            { label: 'Preferable to have', color: '#6DAEDB' }, // Soft Blue        
        ],
    };
    
    



    const getFrameworks = async () => {
        try {
            const res = await axios.get('/frameworks/', { withCredentials: true });
            setUserFrameworks(res.data);
            const selectedFrameIndex = await res.data?.findIndex((framework) => framework._id === selectedFrameworkID);
            if (selectedFrameIndex > -1) {
                setFramework(res.data[selectedFrameIndex]);
            } else {
                setFramework(res.data[0]);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

 
    



    const getSingleFramework = async (frameworkID) => {
        await axios.get('/frameworks/' + frameworkID)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const addEmptyFramework = () => {
        let emptyFramework = { frameworkName: "the framework name placeholder", frameworkDescription: 'the framework description placeholder', roles: [] };
        axios.post('/frameworks/', emptyFramework).then(response => {
            getFrameworks();
            setSelectedFrameworkID(response.data._id);
        });
    }

    const updateFramework = async (frameworkID) => {
        console.log(framework);
        await axios.put('/frameworks/' + frameworkID, { ...framework })
            .then(response => {
                console.log(response);
                getFrameworks();
            })
            .catch(error => {
                console.log(error);
            });
    }

    const removeFramework = async (frameworkID) => {
        await axios.delete('/frameworks/' + frameworkID);
        getFrameworks();
    }

    const addEmptyRole = () => {
        let emptyRole = { _id: uuid(), name: "", description: '', functions: [] };
        let updatedFramework = { frameworkName: framework.name, frameworkDescription: framework.description, roles: [...(framework.roles), emptyRole] };
        setFramework(updatedFramework);
    }

    const removeRole = (roleID) => {
        let updatedFramework = { ...framework };
        let toDeleteRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toDeleteRoleIndex > -1) {
            updatedFramework.roles.splice(toDeleteRoleIndex, 1);
            console.log(roleID + " has been deleted");
            setFramework(updatedFramework);
        }
    }

    const addEmptyFunction = (roleID) => {
        let emptyFunction = { _id: uuid(), name: "", description: '', competencies: [] };
        let updatedFramework = { ...framework };
        let toChangeRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toChangeRoleIndex > -1) {
            updatedFramework.roles[toChangeRoleIndex].functions.push(emptyFunction);
            setFramework(updatedFramework);
        } else {
            console.log('did not find the role');
        }
    }

    const removeFunction = (roleID, functionID) => {
        let updatedFramework = { ...framework };
        let toDeleteRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toDeleteRoleIndex > -1) {
            let toDeleteFunctionIndex = updatedFramework.roles[toDeleteRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
            if (toDeleteFunctionIndex > -1) {
                updatedFramework.roles[toDeleteRoleIndex].functions.splice(toDeleteFunctionIndex, 1);
                setFramework(updatedFramework);
            }
        }
    }

    const addEmptyCompetency = (roleID, functionID) => {
        let emptyCompetency = {
            _id: uuid(), name: "", description: "",
            level: competencySettings.level[0].label,
            type: competencySettings.type[0].label,
            required: competencySettings.required[0].label,
            requirementof: []
        };
        let updatedFramework = { ...framework };
        let toChangeRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toChangeRoleIndex > -1) {
            let toChangeFunctionIndex = updatedFramework.roles[toChangeRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
            if (toChangeFunctionIndex > -1) {
                updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies.push(emptyCompetency);
                setFramework(updatedFramework);
            }
        }
    }

    const removeCompetency = (roleID, functionID, competencyID) => {
        let updatedFramework = { ...framework };
        let toDeleteRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
        if (toDeleteRoleIndex > -1) {
            let toDeleteFunctionIndex = updatedFramework.roles[toDeleteRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
            if (toDeleteFunctionIndex > -1) {
                let toDeleteCompetencyIndex = updatedFramework.roles[toDeleteRoleIndex].functions[toDeleteFunctionIndex].competencies.findIndex((theCompetency) => theCompetency._id === competencyID);
                if (toDeleteCompetencyIndex > -1) {
                    updatedFramework.roles[toDeleteRoleIndex].functions[toDeleteFunctionIndex].competencies.splice(toDeleteCompetencyIndex, 1);
                    setFramework(updatedFramework);
                }
            }
        }
    }

    const toggleEditModal = () => {
        setEditModalIsVisible(!editModalIsVisible);
    }

    const changeSingleValue = (fieldName, fieldValue, roleID = '*', functionID = '*', competencyID = '*') => {
        let updatedFramework = { ...framework };
        if (roleID !== '*') {
            let toChangeRoleIndex = updatedFramework.roles.findIndex((role) => role._id === roleID);
            if (functionID !== '*') {
                let toChangeFunctionIndex = updatedFramework.roles[toChangeRoleIndex].functions.findIndex((theFunction) => theFunction._id === functionID);
                if (competencyID !== '*') {
                    let toChangeCompetencyIndex = updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies.findIndex((competency) => competency._id === competencyID);
                    updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies[toChangeCompetencyIndex][fieldName] = fieldValue;
                } else {
                    updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex][fieldName] = fieldValue;
                }
            } else {
                updatedFramework.roles[toChangeRoleIndex][fieldName] = fieldValue;
            }
        }
        setFramework(updatedFramework);
    }

    // Function to generate a shareable token for a framework
    const generateShareToken = async (frameworkID) => {
        try {
            const response = await axios.post('/utilities/generate-share-token', { idToTokenize: frameworkID });
            return response.data.token;
        } catch (error) {
            console.error('Error generating share token:', error);
            return null;
        }
    };
    const getSharedFramework = async (token) => {
        try {
            // Use GET and pass the token as a query parameter
            const response = await axios.get(`/frameworks/get-shared-framework?token=${token}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching shared framework:', error);
            return null;
        }
    };
    

    const value = {
        framework,
        selectedCompetency,
        setSelectedCompetency,
        setTooltipPosition,
        tooltipPosition,
        tooltipIsVisible,
        setTooltipIsVisible,
        addEmptyRole,
        removeRole,
        addEmptyFunction,
        addEmptyCompetency,
        setFramework,
        changeSingleValue,
        competencySettings,
        coloredBy,
        setColoredBy,
        editModalIsVisible,
        toggleEditModal,
        setEditModalIsVisible,
        message,
        setMessage,
        removeFunction,
        removeCompetency,
        userFrameworks,
        setUserFrameworks,
        selectedFrameworkID,
        setSelectedFrameworkID,
        addEmptyFramework,
        getFrameworks,
        removeFramework,
        updateFramework,
        generateShareToken, // Added the new function
        getSharedFramework
    };

    return (
        <FrameworkContext.Provider value={value}>
            {children}
        </FrameworkContext.Provider>
    )
}

export default FrameworkContextprovider;
