import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Framework from '../components/Framework';
import { useFramework } from '../context/FrameworkContext';
import CompetencyHoverTooltip from './CompetencyHoverTooltip';

// Use `useLocation` to access the current URL and query parameters
const SharedFrameworkView = () => {
   
    const {token} = useParams();
    const [isLoading,setIsLoading] = useState(true);

    const { getSharedFramework, framework, setFramework } = useFramework();

    useEffect(() => {
        const fetchFramework = async () => {
            const response = await getSharedFramework(token);
            if (response) {
                console.log(response);
                setFramework(response);
                setIsLoading(false);
            }
        };

        if (token) {
            fetchFramework();
        }
    }, []);

    return (
        <div>
            {(!isLoading&& framework!=null)?<><Framework /><CompetencyHoverTooltip /></>:<p>Loading...</p>}
        </div>
    );
};

export default SharedFrameworkView;
