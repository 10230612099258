import React from 'react'
import { Container} from 'react-bootstrap'
import Header from '../components/Header';

export default function SettingsPage() {
  
  return (
   <>

   <Container>
   
        <p className="appPageTitle">Settings</p>

   </Container>



   </>
  )
}
