import React, { useContext, useState, useEffect } from "react";
import { redirect } from "react-router-dom";
import axios from "../api/axios";
import config from "../api/config";


const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthContextprovider({ children }) {
    
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasCheckedSession, setHasCheckedSession] = useState(false);
  const [loading, setLoading] = useState(false);

  // Login function
  const login = async ({ email, password, provider } = { provider: 'local' }) => {
    try {
      let response;
      if (provider === 'local') {
        response = await axios.post('/auth/local', { email, password }, { withCredentials: true });
      } else if (provider === 'google' || provider === 'facebook') {
        window.location.href = `${config.apiBaseURL}/auth/${provider}`;
        return;
      }
      if (response && response.data.user) {
        setUser(response.data.user);
        setIsAuthenticated(true);
        return response.data.message;
      }
      // if(response && response.data.error){ {
      //   return { message: { success: false, message: response.data.error } }; 
      // }}
    } catch (error) {
      console.error('Login failed:', error);
      return { message: { success: false, message: "Login Failed" } };
    }
  };

  // Check user session
  const checkSession = async () => {
    try {
      const res = await axios.get('/auth/session', { withCredentials: true });
  
      if (res.data.user) {
        setUser(res.data.user);
        setIsAuthenticated(true);
  
        // If there is a redirect URL and it's not the current location
        if (res.data.redirect && res.data.redirect.length > 1) {
          if (window.location.pathname !== res.data.redirect) {
            // Redirect only if the current path is not already the target path
            window.location.href = res.data.redirect;
          }
        }
      } else {
        throw new Error('Not authenticated');
      }
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setHasCheckedSession(true);
      setLoading(false);
    }
  };

  const logout = async () => {
    
    const res=await axios.post('/auth/logout');
    if(res.data.success){
      setUser(null);
      setIsAuthenticated(false);
      redirect('/signin');
    } else {
        console.log('Logout failed:', res.data.message);
    }
  };

  const registerUser = async (user) => {
    try {
      await axios.post('/auth/register', 
        { data:user }, {
        headers: { 'content-type': 'application/json' }, withCredentials: true
        
      });
      return { success: true, message: "Successfully registered" };
    } catch (err) {
      if (!err?.response) {
        return { success: false, message: "No Server Response" };
      } else if (err.response?.status === 409) {
        return { success: false, message: "Name Taken" };
      } else {
        return { success: false, message: err.message };
      }
    }
  };

  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.put('/auth/change-password', { currentPassword, newPassword }, {
        withCredentials: true
      });
      return response.data;
    } catch (err) {
      if (!err?.response) {
        return { success: false, message: "No Server Response" };
      } else if (err.response?.status === 409) {
        return { success: false, message: "Password change failed due to resource conflict" };
      } else {
        return { success: false, message: "Something went wrong" };
      }
    }
  };

  const updateUserDetails = async (details) => {
    try {
      const response = await axios.post('/auth/update-user-details', details, {
        withCredentials: true,
      });
      return response.data;
    } catch (err) {
      if (!err?.response) {
        return { success: false, message: "No Server Response" };
      } else {
        return { success: false, message: "Failed to update user details" };
      }
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await axios.get('/auth/get-user-details', {
        withCredentials: true
      });
      return response.data;
    } catch (err) {
      if (!err?.response) {
        return { success: false, message: "No Server Response" };
      } else {
        return { success: false, message: "Failed to retrieve user details" };
      }
    }
  };

  // Add the new uploadProfilePicture function
  const uploadProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/auth/upload-profile-picture', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return { success: true, url: response.data.url }; // Return the uploaded image URL
    } catch (err) {
      console.error('Error uploading profile picture:', err);
      return { success: false, message: "Failed to upload profile picture" };
    }
  };

  const value = {

    user,
    login,
    logout,
    isAuthenticated,
    registerUser,
    checkSession,
    hasCheckedSession,
    loading,
    changePassword,
    updateUserDetails,
    getUserDetails,
    uploadProfilePicture, // Include the new uploadProfilePicture function in the context value
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextprovider;
