import React, { useEffect } from "react";
import CourseView from "./CourseView";
import GenericList from "./GenericList";
import { useCourse } from "../context/CourseContext";
import { Row, Col } from "react-bootstrap";
import "./CourseList.css";
import SideMenuToggle from "./SideMenuToggle";

export default function CoursesList(props) {
  const {
    getUserCourses,
    setActionType,
    setEditModalIsVisible,
    removeCourse,
    saveCourse,
    userCourses,
    selectedCourseId,
    setSelectedCourseId,
    message,
    setMessage,
    setSelectedCourse,
  } = useCourse();

  // Initialize courses and select the initial course if provided
  useEffect(() => {
    const initializeCourses = async () => {
      await getUserCourses();
      if (props.initialCourseId) {
        selectCourseById(props.initialCourseId);
      } else if (userCourses?.length > 0) {
        selectCourseById(userCourses[0]._id); // Select the first course by default
      }
    };
    initializeCourses();
  }, [props.initialCourseId]);

  // Function to select a course by ID
  const selectCourseById = (courseId) => {
    setSelectedCourseId(courseId);
    const currentCourse = userCourses.find((course) => course._id === courseId);
    if (currentCourse) {
      setSelectedCourse(currentCourse);
    }
  };

  // Handle course selection from the list
  const handleSelect = (event) => {
    const selectedId = event.target.getAttribute("selectid");
    if (selectedId) {
      selectCourseById(selectedId);
    }
  };

  // Handle deleting a course
  const handleDelete = (event) => {
    const deleteId = event.target.getAttribute("deleteid");
    if (deleteId) {
      selectCourseById(deleteId);
      setMessage({
        target: "Courses",
        header: "Are you sure?",
        text: "Are you sure you want to delete this course and all its related information?",
        variant: "warning",
        buttons: [
          { text: "Yes, I am sure", action: () => handleCompleteDelete(deleteId) },
          { text: "Cancel", action: handleCancel },
        ],
        isVisible: true,
      });
    }
  };

  // Confirm deletion of a course
  const handleCompleteDelete = async (courseId) => {
    await removeCourse(courseId);
    setMessage({ target: "Courses", header: "", text: "", variant: "", buttons: [], isVisible: false });

    const remainingCourses = userCourses.filter((course) => course._id !== courseId);
    if (remainingCourses.length > 0) {
      selectCourseById(remainingCourses[0]._id);
    } else {
      setSelectedCourseId(null);
      setSelectedCourse(null);
    }
  };

  // Cancel deletion
  const handleCancel = () => {
    setMessage({ target: "Courses", header: "", text: "", variant: "", buttons: [], isVisible: false });
  };

  // Handle editing a course
  const handleEdit = (event) => {
    const editId = event.target.getAttribute("editid");
    if (editId) {
      selectCourseById(editId);
      setActionType("edit");
      setEditModalIsVisible(true);
    }
  };

  // Handle adding a new course
  const handleAdd = async () => {
    const newCourseId = await saveCourse();
    if (newCourseId) {
      selectCourseById(newCourseId);
    }
  };

  return (
    <div className="courseListContainer">
      <Row>
        <SideMenuToggle >
        <Col lg="3" md="4" sm="12" sx="12">
          <GenericList
            listHeader="Courses"
            columnsHeaders={["", "Code:"]}
            columnsNames={["name", "code"]}
            data={userCourses}
            handleSelect={handleSelect}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleAdd={handleAdd}
            selectedItemId={selectedCourseId}
            listMessage={message}
          />
        </Col>
        </SideMenuToggle>
        <Col >
          <CourseView
            data={userCourses.find((course) => course._id === selectedCourseId) || null}
          />
        </Col>
      </Row>
    </div>
  );
}
