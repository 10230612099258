import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axios from 'axios';
import config from '../api/config'; // Adjust the import for your API config

export default function PayPalPaymentComponent({ userId, purchasedPlan }) {
    const yearlyCostValue = 12 * parseFloat(purchasedPlan.cost);
  const handlePaymentSuccess = async (paymentDetails) => {
    try {
      // Sending the payment details and user subscription info to the backend
      const response = await axios.post(`${config.apiBaseURL}/subscription/success`, {
        userId,            // The user making the purchase
        purchasedPlan,     // The plan being purchased           
        paymentDetails,    // PayPal payment details
      });

      alert('Subscription successful');
      console.log(response.data);
    } catch (error) {
      console.error('Error during subscription:', error);
    }
  };

  return (
    <PayPalScriptProvider options={{ "client-id": "AcWesfvyAYASLC8DArXtu74bFHkzj1W3dYq6AISZswkrEmrxGkCG7AIbVVpmGzy5OSK_7F-VAGR6ZIji", "currency": "CAD" }}>
      <PayPalButtons
        fundingSource="card"
        createOrder={(data, actions) => {
          // First parse the cost to a float
         
          
          if (isNaN(yearlyCostValue)) {
            alert('Invalid plan cost');
            return; // Prevent order creation if cost is invalid
          }
          alert( yearlyCostValue.toFixed(2))
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: yearlyCostValue.toFixed(2), // Convert to string with two decimal places
                  currency: "CAD"
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            handlePaymentSuccess(details);
          });
        }}
      />
    </PayPalScriptProvider>
  );
}
