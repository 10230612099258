import React, { useState, useEffect } from "react";
import axios from '../api/axios';
import config from '../api/config';
import "./ContactUsForm.css";

const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  // Initialize reCAPTCHA when the component is mounted
  useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        console.log("reCAPTCHA is ready");
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    setIsDisabled(true);

    try {
      // Execute reCAPTCHA and get the token
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute('6LdM0EQqAAAAAEfHKgJqAg3MALvFg3htucT6nc8h', { action: 'submit' });
        setRecaptchaToken(token); // Store the token in state

        // Send the form data along with the reCAPTCHA token
        await axios.post(`${config.apiBaseURL}/contact`, {
          name,
          email,
          message,
          recaptchaToken: token,  // Pass the token to the backend
        });

        setStatus("Message sent! We will get back to you soon.");
        setName("");
        setEmail("");
        setMessage("");
      });
    } catch (error) {
      setStatus("Oops! Something went wrong. Please try again later.");
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="contactFormContainer">
      <h3>Send us a message</h3>

      {status && <p className="status-message">{status}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group row">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group row">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" disabled={isDisabled}>Send Message</button>
      </form>
    </div>
  );
};

export default ContactUsForm;
